function cultureInit() {
    let allTrigger = $(".culture-trigger-item");
    let allContents = $(".culture-target-item");
    let allMobileContents = $(".culture-mobile-item");

    $(".culture-trigger-item").each(function (index) {
        let _this = $(this);
        let siblingTitles = allTrigger.not(_this);
        let content = allContents.eq(index);
        let mobileContent = _this.next();
        let siblingContents = allContents.not($(content));
        let siblingMobileContents = allMobileContents.not($(mobileContent));
        _this.on("click", toggleContent);
        function toggleContent(e) {
            e.preventDefault();
            siblingTitles.removeClass("active");
            _this.toggleClass("active");
            $(window).width() > 1024
                ? (siblingContents.hide(), content.fadeIn(700))
                : (siblingMobileContents.slideUp(),
                  mobileContent.slideToggle(700));
        }
    });

    $(".culture-trigger-item").eq(0).trigger("click");
}

cultureInit()
