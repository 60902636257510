$(".expand-content").each(function (e) {
	const $this = $(this);
	const fixedHeight = $this.outerHeight();
	const innerContentHeight = $this
		.find(".expand-content-inner")
		.outerHeight();
	const trigger = $this.find(".expand-content-trigger");
	const overlay = $this.find(".expand-content-overlay");
	if (fixedHeight >= innerContentHeight) {
		$(overlay).hide();
	}
	$(trigger).on("click", function (e) {
		e.preventDefault();
		const innerContentHeight = $this
			.find(".expand-content-inner")
			.outerHeight();
		if (!$this.hasClass("show")) {
			$this.addClass("show");
			$(overlay).addClass("active");
			$this.css("max-height", `${innerContentHeight}px`);
		} else {
			$this.removeClass("show");
			$(overlay).removeClass("active");
			$this.css("max-height", `${fixedHeight}px`);
		}
		$(this).toggleHtml(
			`<span>Xem thêm</span> <em class="fal fa-chevron-down"></em>`,
			`<span>Rút gọn</span> <em class="fal fa-chevron-up"></em>`
		);
	});
});

$.fn.extend({
	toggleHtml: function (a, b) {
		return this.html(this.html() == b ? a : b);
	},
});
