!(function (e, t) {
    "object" == typeof exports && "object" == typeof module
        ? (module.exports = t())
        : "function" == typeof define && define.amd
        ? define([], t)
        : "object" == typeof exports
        ? (exports["@master/css"] = t())
        : (e["@master/css"] = t());
})(this, function () {
    return (() => {
        var e = {
                642: (e) => {
                    e.exports = {
                        "3xs": 360,
                        "2xs": 480,
                        xs: 600,
                        sm: 768,
                        md: 1024,
                        lg: 1280,
                        xl: 1440,
                        "2xl": 1600,
                        "3xl": 1920,
                        "4xl": 2560,
                    };
                },
                580: (e) => {
                    e.exports = {
                        fade: {
                            10: "131518",
                            20: "24262d",
                            30: "363944",
                            40: "4c515f",
                            50: "63697c",
                            60: "878d9f",
                            70: "a6abb8",
                            80: "c3c6cf",
                            90: "f4f4f6",
                        },
                        gray: {
                            10: "151515",
                            20: "242424",
                            30: "39393b",
                            40: "515153",
                            50: "6a6a6c",
                            60: "8d8d8f",
                            70: "aaaaac",
                            80: "c6c6c8",
                            90: "f4f4f6",
                        },
                        brown: {
                            10: "1c130f",
                            20: "31221b",
                            30: "4b3429",
                            40: "6a4a3a",
                            50: "8a604c",
                            60: "af836e",
                            70: "c4a394",
                            80: "d8c2b8",
                            90: "f8f3f1",
                        },
                        orange: {
                            10: "221205",
                            20: "3a1f08",
                            30: "582f0d",
                            40: "7c4312",
                            50: "a15717",
                            60: "d5731e",
                            70: "e79855",
                            80: "efbd92",
                            90: "fcf1e7",
                        },
                        gold: {
                            10: "1e1400",
                            20: "342300",
                            30: "4e3400",
                            40: "6e4900",
                            50: "906000",
                            60: "c08000",
                            70: "e89a00",
                            80: "ffba30",
                            90: "fff3da",
                        },
                        yellow: {
                            10: "1b1500",
                            20: "2f2500",
                            30: "473800",
                            40: "634f00",
                            50: "806700",
                            60: "ac8a00",
                            70: "d0a700",
                            80: "f0c100",
                            90: "fff5ca",
                        },
                        grass: {
                            10: "101803",
                            20: "1c2a05",
                            30: "2a4008",
                            40: "3c5a0b",
                            50: "4e750e",
                            60: "689c13",
                            70: "7dbc17",
                            80: "92da1a",
                            90: "ebfad4",
                        },
                        green: {
                            10: "011a0b",
                            20: "022d13",
                            30: "03441d",
                            40: "055f28",
                            50: "067b34",
                            60: "08a345",
                            70: "0ac553",
                            80: "0be561",
                            90: "d5fde5",
                        },
                        beryl: {
                            10: "001912",
                            20: "002b1f",
                            30: "00432f",
                            40: "005d41",
                            50: "007954",
                            60: "00a170",
                            70: "00c387",
                            80: "00e19c",
                            90: "c9ffee",
                        },
                        teal: {
                            10: "001918",
                            20: "002b28",
                            30: "00413d",
                            40: "005b55",
                            50: "00776f",
                            60: "009f94",
                            70: "00bfb2",
                            80: "00ddce",
                            90: "c5fffb",
                        },
                        cyan: {
                            10: "00181f",
                            20: "002a35",
                            30: "003f51",
                            40: "005973",
                            50: "007391",
                            60: "0099c1",
                            70: "00b9e9",
                            80: "3dd7ff",
                            90: "dff8ff",
                        },
                        sky: {
                            10: "021726",
                            20: "032841",
                            30: "043c61",
                            40: "065489",
                            50: "086eb3",
                            60: "0b92ee",
                            70: "4db3f7",
                            80: "8ccefa",
                            90: "eaf6fe",
                        },
                        blue: {
                            10: "051338",
                            20: "09205e",
                            30: "0d318d",
                            40: "1344c4",
                            50: "175fe9",
                            60: "538cee",
                            70: "81acf3",
                            80: "a5c7fd",
                            90: "edf4fe",
                        },
                        indigo: {
                            10: "161031",
                            20: "24195e",
                            30: "332592",
                            40: "4835cc",
                            50: "5a5bd5",
                            60: "7d84e8",
                            70: "a1a5ee",
                            80: "bfc2f4",
                            90: "f1f2ff",
                        },
                        violet: {
                            10: "1f0839",
                            20: "310e63",
                            30: "491595",
                            40: "641ed2",
                            50: "7949e5",
                            60: "9e77f5",
                            70: "b89bf9",
                            80: "d0bdfb",
                            90: "f5f1ff",
                        },
                        purple: {
                            10: "220736",
                            20: "390c5b",
                            30: "551287",
                            40: "7719bd",
                            50: "9832e4",
                            60: "b56cec",
                            70: "ca96f1",
                            80: "dcbaf6",
                            90: "f9f0ff",
                        },
                        fuchsia: {
                            10: "2b0720",
                            20: "470b3d",
                            30: "68105f",
                            40: "8e1691",
                            50: "b61cbb",
                            60: "e04ee5",
                            70: "ea86ed",
                            80: "f1b1f3",
                            90: "feefff",
                        },
                        pink: {
                            10: "2d0519",
                            20: "4c082b",
                            30: "720c40",
                            40: "9d1059",
                            50: "ca1473",
                            60: "ee52a3",
                            70: "f388c0",
                            80: "f7b2d6",
                            90: "fff0f8",
                        },
                        crimson: {
                            10: "33020f",
                            20: "500317",
                            30: "780522",
                            40: "a20d35",
                            50: "ce1a4b",
                            60: "ea5b82",
                            70: "f58ba7",
                            80: "ffb1c6",
                            90: "fff1f4",
                        },
                        red: {
                            10: "350001",
                            20: "530001",
                            30: "780506",
                            40: "a60708",
                            50: "d11a1e",
                            60: "eb5f63",
                            70: "fa8b8d",
                            80: "fdb3b5",
                            90: "fff1f1",
                        },
                        black: "000000",
                        white: "ffffff",
                    };
                },
                989: (e, t, s) => {
                    const i = s(824),
                        r = s(860);
                    s(580);
                    e.exports = function (e) {
                        "string" == typeof e && (e = { "": e });
                        const t = "" in e;
                        let s = !1;
                        for (const t in e)
                            if (t && +t >= 100) {
                                s = !0;
                                break;
                            }
                        if (!s && (!t || Object.keys(e).length > 1)) {
                            let t,
                                s,
                                n = 0,
                                a = "0" in e ? i(e[0]) : [0, 0, 0];
                            const c = [],
                                l = () => {
                                    const i = t - n,
                                        l = s.map((e, t) => (e - a[t]) / i);
                                    for (const t of c) {
                                        const s = t - n,
                                            i = a.map((e, t) =>
                                                Math.round(e + l[t] * s)
                                            );
                                        e[t] = r.call(this, ...i);
                                    }
                                };
                            for (let r = 1; r < 100; r++) {
                                const o = r % 2 == 0;
                                r in e
                                    ? (c.length
                                          ? ((t = r),
                                            (s = i(e[r])),
                                            l(),
                                            (c.length = 0),
                                            (a = s))
                                          : (a = i(e[r])),
                                      (n = r))
                                    : o && c.push(r);
                            }
                            c.length &&
                                ((t = 100),
                                (s = "100" in e ? i(e[100]) : [255, 255, 255]),
                                l());
                        }
                        return t || (e[""] = e[s ? "500" : "50"]), e;
                    };
                },
                824: (e) => {
                    e.exports = function (e) {
                        const t = e.match(/.{1,2}/g);
                        return [
                            parseInt(t[0], 16),
                            parseInt(t[1], 16),
                            parseInt(t[2], 16),
                        ];
                    };
                },
                860: (e) => {
                    e.exports = function (e, t, s) {
                        return ((1 << 24) + (e << 16) + (t << 8) + s)
                            .toString(16)
                            .slice(1);
                    };
                },
            },
            t = {};
        function s(i) {
            var r = t[i];
            if (void 0 !== r) return r.exports;
            var n = (t[i] = { exports: {} });
            return e[i](n, n.exports, s), n.exports;
        }
        (s.n = (e) => {
            var t = e && e.__esModule ? () => e.default : () => e;
            return s.d(t, { a: t }), t;
        }),
            (s.d = (e, t) => {
                for (var i in t)
                    s.o(t, i) &&
                        !s.o(e, i) &&
                        Object.defineProperty(e, i, {
                            enumerable: !0,
                            get: t[i],
                        });
            }),
            (s.o = (e, t) => Object.prototype.hasOwnProperty.call(e, t)),
            (s.r = (e) => {
                "undefined" != typeof Symbol &&
                    Symbol.toStringTag &&
                    Object.defineProperty(e, Symbol.toStringTag, {
                        value: "Module",
                    }),
                    Object.defineProperty(e, "__esModule", { value: !0 });
            });
        var i = {};
        return (
            (() => {
                "use strict";
                function e(e, t) {
                    return (
                        e +
                        ":" +
                        (t.unit ? t.value + t.unit : t.value) +
                        (t.important ? "!important" : "")
                    );
                }
                s.r(i),
                    s.d(i, {
                        Style: () => $t,
                        StyleSheet: () => Ct,
                        Styles: () => qn,
                        breakpoints: () => Qn,
                        colors: () => Fn,
                        init: () => Zn,
                        sheets: () => Nt,
                    });
                const t = /^([+-.]?\d+(\.?\d+)?)(.*)?/;
                function r(e, s, i, r, n) {
                    let a = r ? r[e] : "",
                        c = "",
                        l = "";
                    if (a) return { value: a, unit: c, unitToken: l };
                    if ("number" == typeof e) (a = e), (c = s || "");
                    else {
                        if (i) {
                            const t = Object.keys(i);
                            e = e.replace(
                                new RegExp(
                                    `(^|,| |\\()(${t.join(
                                        "|"
                                    )})(?:-([0-9]+))?(?:\\/(\\.?[0-9]+))?(?=(\\)|\\}|,| |$))`,
                                    "gm"
                                ),
                                (e, t, s, r, n, a) => {
                                    const c = i[s][r || ""];
                                    if (c) {
                                        let e = "#" + c;
                                        if (n) {
                                            let t = +n;
                                            (t = isNaN(t)
                                                ? 1
                                                : Math.min(Math.max(t, 0), 1)),
                                                (e += Math.round(255 * t)
                                                    .toString(16)
                                                    .toUpperCase()
                                                    .padStart(2, "0"));
                                        }
                                        return t + e;
                                    }
                                    return e;
                                }
                            );
                        }
                        if (s) {
                            const i = e.match(t);
                            if (i) {
                                if (e.includes("/")) {
                                    const t = e.split("/");
                                    return {
                                        value: (+t[0] / +t[1]) * 100 + "%",
                                        unit: c,
                                        unitToken: l,
                                    };
                                }
                                return (
                                    (a = +i[1]),
                                    (c = l = i[3] || ""),
                                    c ||
                                        (("rem" !== s && "em" !== s) ||
                                            (a /= n),
                                        (c = s || "")),
                                    { value: a, unit: c, unitToken: l }
                                );
                            }
                        }
                        a = (
                            -1 === e.indexOf("calc(")
                                ? e
                                : (function (e) {
                                      const t = (e) =>
                                          "+" === e ||
                                          "-" === e ||
                                          "*" === e ||
                                          "/" === e;
                                      let s,
                                          i = "",
                                          r = "",
                                          n = !1;
                                      function a() {
                                          (s = null), (r = "");
                                      }
                                      for (let c = 0; c < e.length; c++) {
                                          const l = e[c];
                                          if ("(" === l || ")" === l)
                                              (n = ")" === l),
                                                  (i += r + l),
                                                  a();
                                          else if ("," === l)
                                              (i += r + l + " "), a();
                                          else {
                                              switch (s) {
                                                  case 1:
                                                      break;
                                                  case 2:
                                                      if (t(l)) {
                                                          (i +=
                                                              r +
                                                              " " +
                                                              l +
                                                              " "),
                                                              a();
                                                          continue;
                                                      }
                                                      break;
                                                  default:
                                                      n && (r += " "),
                                                          isNaN(+l)
                                                              ? t(l) || (s = 1)
                                                              : (s = 2);
                                              }
                                              r += l;
                                          }
                                      }
                                      return r && (i += r), i;
                                  })(e)
                        ).replace(/\$\(((\w|-)+)\)/g, "var(--$1)");
                    }
                    return { value: a, unit: c, unitToken: l };
                }
                const n = [":disabled", ":active", ":focus", ":hover"],
                    a = { "(": ")", "'": "'", "{": "}" };
                var c = s(642),
                    l = s.n(c);
                const o = (...e) => e.join("-"),
                    d = "border",
                    h = "radius",
                    u = "margin",
                    f = "padding",
                    m = "font",
                    p = "currentColor",
                    x = ",",
                    b = "flow",
                    g = "template",
                    y = "column",
                    k = "columns",
                    v = "gap",
                    w = "row",
                    O = "rows",
                    j = "shadow",
                    S = "user",
                    N = "content",
                    $ = "image",
                    I = "stroke",
                    R = "filter",
                    z = "blend",
                    W = "background",
                    A = "repeat",
                    C = "color",
                    E = "scroll",
                    M = "animation",
                    V = "direction",
                    _ = "mode",
                    L = "behavior",
                    T = o("overscroll", L),
                    q = "y",
                    P = "x",
                    Z = "height",
                    F = "width",
                    Q = "max",
                    U = "min",
                    X = "block",
                    Y = "inline",
                    B = "flex",
                    D = "grid",
                    G = "transition",
                    H = "origin",
                    J = "delay",
                    K = "rotate",
                    ee = "deg",
                    te = "timing-function",
                    se = "duration",
                    ie = "display",
                    re = "hidden",
                    ne = "box",
                    ae = "fill",
                    ce = "clip",
                    le = "none",
                    oe = "text",
                    de = "align",
                    he = "transform",
                    ue = "vertical",
                    fe = "word",
                    me = "space",
                    pe = "decoration",
                    xe = "break",
                    be = "size",
                    ge = "auto",
                    ye = "line",
                    ke = "object",
                    ve = "position",
                    we = "rem",
                    Oe = "style",
                    je = "smoothing",
                    Se = "antialiased",
                    Ne = "spacing",
                    $e = o("letter", Ne),
                    Ie = o(Q, F),
                    Re = o(U, F),
                    ze = o(Q, Z),
                    We = o(U, Z),
                    Ae = "table",
                    Ce = "group",
                    Ee = "list",
                    Me = "item",
                    Ve = "span",
                    _e = "justify",
                    Le = "items",
                    Te = "self",
                    qe = "place",
                    Pe = "type",
                    Ze = "offset",
                    Fe = "outline",
                    Qe = "snap",
                    Ue = "shape",
                    Xe = "view",
                    Ye = "area",
                    Be = "start",
                    De = "blur",
                    Ge = "drop",
                    He = {
                        full: "100%",
                        fit: o("fit", N),
                        max: o(Q, N),
                        min: o(U, N),
                    };
                for (const e in l()) He[e] = l()[e] / 19.2 + "rem";
                const Je = s(989),
                    Ke = "matches",
                    et = "semantics",
                    tt = "symbol",
                    st = "max-width",
                    it = "min-width",
                    rt = "motion",
                    nt = "reduce",
                    at = "reduced-motion",
                    ct = "::scrollbar",
                    lt = "::slider-thumb",
                    ot = "::slider-runnable-track",
                    dt = "::search",
                    ht = "::meter",
                    ut = "::resizer",
                    ft = "::progress",
                    mt = "px",
                    pt = ["!", "*", ">", "+", "~", ":", "[", "@", "_"],
                    xt = [...pt, void 0, "."],
                    bt = new RegExp(ct, "g"),
                    gt = new RegExp(dt, "g"),
                    yt = new RegExp(ht, "g"),
                    kt = new RegExp(ot, "g"),
                    vt = new RegExp(lt, "g"),
                    wt = new RegExp(ut, "g"),
                    Ot = new RegExp(ft, "g"),
                    jt =
                        /(\\'(?:.*?)[^\\]\\')(?=[*_>~+,)])|(\[[^=]+='(?:.*?)[^\\]'\])/,
                    St = (e) =>
                        e
                            .split(jt)
                            .map((e, t) => (t % 3 ? e : e.replace(/\_/g, " ")))
                            .join(""),
                    Nt = [];
                class $t {
                    constructor(t, s) {
                        (this.name = t),
                            (this.matching = s),
                            (this.at = {}),
                            (this.prioritySelectorIndex = -1);
                        const i = this.constructor;
                        if (void 0 === s && !(s = i.match(t))) return;
                        let c,
                            l,
                            o,
                            {
                                id: d,
                                semantics: h,
                                unit: u,
                                colors: f,
                                key: m,
                                values: p,
                                colorful: x,
                                breakpoints: b,
                                mediaQueries: g,
                                colorSchemes: y,
                                rootSize: k,
                            } = i,
                            v = t;
                        if ((x || (f = null), s.origin === et))
                            (c = s.value),
                                (o = v.slice(s.value.length)),
                                (this.value = h[s.value]);
                        else {
                            if (s.origin === Ke)
                                if (d === Ce) {
                                    let e = 0;
                                    for (
                                        ;
                                        e < v.length &&
                                        ("{" !== v[e] || "\\" === v[e - 1]);
                                        e++
                                    );
                                    (l = v.slice(0, e)), (c = v.slice(e));
                                } else {
                                    const e = v.indexOf(":");
                                    (this.prefix = v.slice(0, e + 1)),
                                        this.prefix.includes("(")
                                            ? ((this.prefix = void 0), (c = v))
                                            : (c = v.slice(e + 1));
                                }
                            else
                                s.origin === tt &&
                                    ((this.symbol = v[0]), (c = v.slice(1)));
                            let e,
                                t = "",
                                i = [],
                                n = 0;
                            !(function s(l, o, d = "") {
                                let h,
                                    m = !1;
                                for (
                                    l &&
                                    (")" === l && "$" === t.slice(-1)
                                        ? (h = t.length - 1)
                                        : "'" === l && (m = !0),
                                    (t += c[n++]));
                                    n < c.length;
                                    n++
                                ) {
                                    const x = c[n];
                                    if (x === l) {
                                        if (((t += x), m)) {
                                            let e = 0;
                                            for (
                                                let s = t.length - 2;
                                                "\\" === t[s];
                                                s--
                                            )
                                                e++;
                                            if (e % 2) continue;
                                        }
                                        void 0 !== h &&
                                            (t =
                                                t.slice(0, h) +
                                                t
                                                    .slice(h)
                                                    .replace(
                                                        /\$\((.*)\)/,
                                                        "var(--$1)"
                                                    )),
                                            o ||
                                                (m
                                                    ? i.push(t)
                                                    : ((e = r(t, u, f, p, k)),
                                                      i.push(e.value + e.unit)),
                                                (d = ""),
                                                (t = ""));
                                        break;
                                    }
                                    if (!m && x in a)
                                        s(a[x], void 0 === o ? 0 : o + 1, d);
                                    else if (
                                        "|" !== x ||
                                        "}" === l ||
                                        (m && "path" !== d)
                                    ) {
                                        if (!l) {
                                            if ("." === x) {
                                                if (isNaN(+c[n + 1])) break;
                                                "-" === c[n - 1] && (t += "0");
                                            } else {
                                                if ("," === x) {
                                                    (e = r(t, u, f, p, k)),
                                                        i.push(
                                                            e.value + e.unit,
                                                            ","
                                                        ),
                                                        (t = "");
                                                    continue;
                                                }
                                                if (
                                                    ("#" === x &&
                                                        (t ||
                                                            (i.length &&
                                                                "|" !==
                                                                    c[
                                                                        n - 1
                                                                    ]))) ||
                                                    pt.includes(x)
                                                )
                                                    break;
                                            }
                                            d += x;
                                        }
                                        t += x;
                                    } else
                                        l
                                            ? (t += " ")
                                            : ((e = r(t, u, f, p, k)),
                                              i.push(e.value + e.unit),
                                              (t = ""));
                                }
                            })(),
                                t &&
                                    ((e = r(t, u, f, p, k)),
                                    i.push(e.value + e.unit)),
                                (o = c.slice(n)),
                                1 === i.length
                                    ? e
                                        ? ((this.value = e.value),
                                          (this.unit = e.unit))
                                        : ((this.value = i[0]),
                                          (this.unit = ""))
                                    : (this.value = i.reduce(
                                          (e, t, s) =>
                                              e +
                                              t +
                                              ("," === t ||
                                              "," === i[s + 1] ||
                                              s === i.length - 1
                                                  ? ""
                                                  : " "),
                                          ""
                                      ));
                        }
                        o.includes(ct) &&
                            (o = o.replace(bt, "::-webkit-scrollbar")),
                            o.includes(dt) &&
                                (o = o.replace(gt, "::-webkit-search")),
                            o.includes(lt) &&
                                (o = o.replace(vt, "::-webkit-slider-thumb")),
                            o.includes(ot) &&
                                (o = o.replace(
                                    kt,
                                    "::-webkit-slider-runnable-track"
                                )),
                            o.includes(ht) &&
                                (o = o.replace(yt, "::-webkit-meter")),
                            o.includes(ut) &&
                                (o = o.replace(wt, "::-webkit-resizer")),
                            o.includes(ft) &&
                                (o = o.replace(Ot, "::-webkit-progress")),
                            o.includes(":first") &&
                                (o = o.replace(
                                    /:first(?![a-z-])/g,
                                    ":first-child"
                                )),
                            o.includes(":last") &&
                                (o = o.replace(
                                    /:last(?![a-z-])/g,
                                    ":last-child"
                                )),
                            o.includes(":even") &&
                                (o = o.replace(
                                    /:even(?![a-z-])/g,
                                    ":nth-child(2n)"
                                )),
                            o.includes(":odd") &&
                                (o = o.replace(
                                    /:odd(?![a-z-])/g,
                                    ":nth-child(odd)"
                                )),
                            o.includes(":nth(") &&
                                (o = o.replace(/:nth\(/g, ":nth-child(")),
                            this.parseValue && (this.value = this.parseValue),
                            p &&
                                this.value in p &&
                                (this.value = p[this.value]),
                            "!" === o[0] &&
                                ((this.important = !0), (o = o.slice(1))),
                            (this.prefixSelector = l ? St(l) : "");
                        const w = o.split("@");
                        let O = w[0];
                        if (O) {
                            (O = St(O)),
                                (this.hasWhere = O.includes(":where("));
                            for (let e = 0; e < n.length; e++)
                                if (O.includes(n[e])) {
                                    this.prioritySelectorIndex = e;
                                    break;
                                }
                        }
                        this.suffixSelector = O;
                        for (let e = 1; e < w.length; e++) {
                            const t = w[e];
                            if (t)
                                if (y.includes(t)) this.colorScheme = t;
                                else if ("rtl" === t || "ltr" === t)
                                    this.direction = t;
                                else {
                                    let e, s;
                                    const i = t.indexOf("_");
                                    if (-1 !== i)
                                        (e = t.slice(0, i)), (s = t.slice(i));
                                    else {
                                        const i = t.indexOf("(");
                                        -1 !== i &&
                                            ((e = t.slice(0, i)),
                                            (s = t.slice(i)));
                                    }
                                    if (!e) {
                                        e = "media";
                                        const i = [];
                                        this.media = { token: t, features: {} };
                                        const n = t.split("&");
                                        for (const e of n)
                                            if (
                                                "all" === e ||
                                                "print" === e ||
                                                "screen" === e ||
                                                "speech" === e
                                            )
                                                this.media.type = e;
                                            else if ("🖨" === e)
                                                this.media.type = "print";
                                            else if (
                                                "landscape" === e ||
                                                "portrait" === e
                                            )
                                                i.push(
                                                    "(orientation:" + e + ")"
                                                );
                                            else if (e === rt || e === at)
                                                i.push(
                                                    "(prefers-reduced-motion:" +
                                                        (e === rt
                                                            ? "no-preference"
                                                            : nt) +
                                                        ")"
                                                );
                                            else if (e in g) i.push(g[e]);
                                            else {
                                                const t = { token: e };
                                                let s = "",
                                                    n = "",
                                                    a = 0;
                                                switch (
                                                    (e.startsWith("<=")
                                                        ? ((n = "<="), (s = st))
                                                        : e.startsWith(">=") ||
                                                          b[e]
                                                        ? ((n = ">="), (s = it))
                                                        : e.startsWith(">")
                                                        ? ((n = ">"),
                                                          (s = it),
                                                          (a = 0.02))
                                                        : e.startsWith("<") &&
                                                          ((n = "<"),
                                                          (s = st),
                                                          (a = -0.02)),
                                                    s)
                                                ) {
                                                    case st:
                                                    case it:
                                                        const c = n
                                                                ? e.replace(
                                                                      n,
                                                                      ""
                                                                  )
                                                                : e,
                                                            l = b[c];
                                                        l
                                                            ? Object.assign(
                                                                  t,
                                                                  r(l, mt)
                                                              )
                                                            : Object.assign(
                                                                  t,
                                                                  r(c, mt)
                                                              ),
                                                            t.unit === mt &&
                                                                (t.value += a),
                                                            (this.media.features[
                                                                s
                                                            ] = t),
                                                            i.push(
                                                                "(" +
                                                                    s +
                                                                    ":" +
                                                                    (t.value +
                                                                        t.unit) +
                                                                    ")"
                                                            );
                                                }
                                            }
                                        (s = ""),
                                            this.media.type &&
                                                (s = this.media.type),
                                            i.length &&
                                                (s +=
                                                    (s ? " and " : "") +
                                                    i.join(" and "));
                                    }
                                    s &&
                                        (this.at[e] =
                                            (e in this.at
                                                ? this.at[e] + " and "
                                                : "") + s.replace(/\_/g, " "));
                                }
                        }
                        let j = "";
                        this.colorScheme && (j += "." + this.colorScheme + " "),
                            this.prefixSelector && (j += this.prefixSelector),
                            this.direction &&
                                (j += "[dir=" + this.direction + "] "),
                            (this.text =
                                j +
                                "." +
                                CSS.escape(this.name) +
                                this.suffixSelector +
                                (this.name in $t.relations
                                    ? $t.relations[this.name]
                                          .map(
                                              (e) =>
                                                  ", " +
                                                  j +
                                                  "." +
                                                  CSS.escape(e) +
                                                  this.suffixSelector
                                          )
                                          .join("")
                                    : "") +
                                "{" +
                                ("object" == typeof this.value
                                    ? Object.keys(this.value)
                                          .map((t) =>
                                              e(
                                                  t,
                                                  Object.assign(
                                                      Object.assign({}, this),
                                                      {
                                                          unit: "",
                                                          value: this.value[t],
                                                      }
                                                  )
                                              )
                                          )
                                          .join(";")
                                    : this.props
                                    ? Object.keys(this.props)
                                          .map((t) => e(t, this.props[t]))
                                          .join(";")
                                    : e(m, this)) +
                                "}");
                        for (const e of Object.keys(this.at).sort((e, t) =>
                            "supports" === t ? -1 : 1
                        ))
                            this.text =
                                "@" +
                                e +
                                " " +
                                this.at[e] +
                                "{" +
                                this.text +
                                "}";
                        void 0 === this.order && (this.order = 0);
                    }
                    static match(e) {
                        if (this.matches && this.matches.test(e))
                            return { origin: Ke };
                        if (this.semantics)
                            for (const t in this.semantics)
                                if (
                                    e === t ||
                                    (e.startsWith(t) &&
                                        xt.includes(e[t.length]))
                                )
                                    return { origin: et, value: t };
                        return this.colorStarts &&
                            (e.match(
                                "^" +
                                    this.colorStarts +
                                    "(#|(rgb|hsl)\\(.*\\))((?!\\|).)*$"
                            ) ||
                                (this.colorNames.length &&
                                    e.match(
                                        "^" +
                                            this.colorStarts +
                                            "(" +
                                            this.colorNames.join("|") +
                                            ")"
                                    ) &&
                                    -1 === e.indexOf("|")))
                            ? { origin: Ke }
                            : this.symbol && e.startsWith(this.symbol)
                            ? { origin: tt }
                            : this.key && e.startsWith(this.key + ":")
                            ? { origin: Ke }
                            : void 0;
                    }
                    static extend(e, ...t) {
                        if (!t.length) return this;
                        const s = Object.assign({}, ...t),
                            i = (e, t, i) => {
                                var r;
                                for (const n in s) {
                                    const a = s[n];
                                    null == a
                                        ? n in e &&
                                          (null == i || i(n), delete e[n])
                                        : (e[n] =
                                              null !==
                                                  (r =
                                                      null == t
                                                          ? void 0
                                                          : t(n, a)) &&
                                              void 0 !== r
                                                  ? r
                                                  : a);
                                }
                            };
                        switch (e) {
                            case "classes":
                                i(
                                    this.classes,
                                    (e, t) => {
                                        if (e in this.classes)
                                            for (const t in this.relations) {
                                                const s = this.relations[t],
                                                    i = s.indexOf(e);
                                                -1 !== i &&
                                                    (s.length > 1
                                                        ? s.splice(i, 1)
                                                        : delete this.relations[
                                                              t
                                                          ]);
                                            }
                                        const s = Array.isArray(t)
                                            ? t
                                            : t
                                                  .replace(
                                                      /(?:\n(?:\s*))+/g,
                                                      " "
                                                  )
                                                  .trim()
                                                  .split(" ");
                                        for (const t of s)
                                            t in this.relations
                                                ? this.relations[t].push(e)
                                                : (this.relations[t] = [e]);
                                        return s;
                                    },
                                    (e) => {
                                        for (const t of this.classes[e]) {
                                            const s = this.relations[t];
                                            s.length > 1
                                                ? s.splice(s.indexOf(e), 1)
                                                : delete this.relations[t];
                                        }
                                    }
                                );
                                break;
                            case "colors":
                                i(
                                    this.colors,
                                    (e, t) => (
                                        -1 === this.colorNames.indexOf(e) &&
                                            this.colorNames.push(e),
                                        Je(t)
                                    ),
                                    (e) => {
                                        const t = this.colorNames.indexOf(e);
                                        -1 !== t &&
                                            this.colorNames.splice(t, 1);
                                    }
                                );
                                break;
                            default:
                                let t = this[e];
                                t || (t = this[e] = {}), i(t);
                        }
                        return this;
                    }
                }
                ($t.unit = "rem"),
                    ($t.rootSize = 19.2),
                    ($t.mediaQueries = {}),
                    ($t.sheets = Nt),
                    ($t.colors = {}),
                    ($t.classes = {}),
                    ($t.colorNames = []),
                    ($t.relations = {}),
                    ($t.colorSchemes = ["dark", "light"]),
                    "undefined" != typeof window && (window.MasterStyle = $t);
                const It = [
                        ",",
                        ".",
                        "#",
                        "[",
                        "!",
                        "*",
                        ">",
                        "+",
                        "~",
                        ":",
                        "@",
                    ],
                    Rt = "undefined" != typeof document;
                let zt;
                Rt &&
                    ((zt = document.createElement("style")),
                    (zt.id = "master-css"));
                const Wt = "undefined" != typeof window,
                    At = Wt ? window.MutationObserver : Object;
                class Ct extends At {
                    constructor(e) {
                        if (
                            (super((e) => {
                                const t = {},
                                    s = [],
                                    i = [],
                                    r = [],
                                    n = (e, t) => {
                                        t
                                            ? e.classList.forEach(c)
                                            : e.classList.forEach(a);
                                        const s = e.children;
                                        for (let e = 0; e < s.length; e++) {
                                            const r = s[e];
                                            r.classList && (i.push(r), n(r, t));
                                        }
                                    },
                                    a = (e) => {
                                        e in t ? t[e]++ : (t[e] = 1);
                                    },
                                    c = (e) => {
                                        e in t
                                            ? t[e]--
                                            : e in this.countOfName &&
                                              (t[e] = -1);
                                    },
                                    l = (e, t) => {
                                        for (let s = 0; s < e.length; s++) {
                                            const a = e[s];
                                            !a.classList ||
                                                i.includes(a) ||
                                                r.includes(a) ||
                                                (a.isConnected !== t
                                                    ? (i.push(a), n(a, t))
                                                    : r.push(a));
                                        }
                                    };
                                for (let t = 0; t < e.length; t++) {
                                    const r = e[t],
                                        {
                                            addedNodes: n,
                                            removedNodes: a,
                                            type: c,
                                            target: o,
                                            oldValue: d,
                                        } = r;
                                    if ("attributes" === c) {
                                        if (s.find((e) => e.target === o))
                                            continue;
                                        s.push(r);
                                    } else
                                        l(n, !1),
                                            (o.isConnected && i.includes(o)) ||
                                                l(a, !0);
                                }
                                if (s.length || Object.keys(t).length) {
                                    for (const {
                                        oldValue: e,
                                        target: t,
                                    } of s) {
                                        const s = i.includes(t),
                                            r = t.classList,
                                            n = e ? e.split(" ") : [];
                                        if (s) {
                                            if (t.isConnected) continue;
                                            for (const e of n)
                                                r.contains(e) || c(e);
                                        } else if (t.isConnected) {
                                            r.forEach((e) => {
                                                n.includes(e) || a(e);
                                            });
                                            for (const e of n)
                                                r.contains(e) || c(e);
                                        }
                                    }
                                    for (const e in t) {
                                        const s = t[e],
                                            i = (this.countOfName[e] || 0) + s;
                                        0 === i
                                            ? (delete this.countOfName[e],
                                              this.delete(e))
                                            : (e in this.countOfName ||
                                                  this.findAndInsert(e),
                                              (this.countOfName[e] = i));
                                    }
                                }
                            }),
                            (this.container = e),
                            (this.styles = []),
                            (this.styleOfName = {}),
                            (this.countOfName = {}),
                            Rt)
                        ) {
                            if (e) {
                                const t = e.querySelector('[id="master-css"]');
                                if (t) {
                                    this.element = t;
                                    const e = (t, s) => {
                                        if (t.selectorText) {
                                            const e = t.selectorText
                                                .split(", ")[0]
                                                .split(" ");
                                            for (let i = 0; i < e.length; i++) {
                                                const r = e[i];
                                                if ("." === r[0]) {
                                                    const e = r.slice(1);
                                                    let i = "";
                                                    for (
                                                        let t = 0;
                                                        t < e.length;
                                                        t++
                                                    ) {
                                                        const s = e[t],
                                                            r = e[t + 1];
                                                        if ("\\" === s) {
                                                            if (
                                                                (t++,
                                                                "\\" !== r)
                                                            ) {
                                                                i += r;
                                                                continue;
                                                            }
                                                        } else if (
                                                            It.includes(s)
                                                        )
                                                            break;
                                                        i += s;
                                                    }
                                                    if (
                                                        !(
                                                            i in
                                                            this.styleOfName
                                                        ) &&
                                                        !(i in $t.classes)
                                                    ) {
                                                        const e =
                                                            Ct.findAndNew(i);
                                                        e &&
                                                            ((e.cssRule =
                                                                null != s
                                                                    ? s
                                                                    : t),
                                                            this.styles.push(e),
                                                            (this.styleOfName[
                                                                e.name
                                                            ] = e));
                                                    }
                                                }
                                            }
                                        } else if (t.cssRules)
                                            for (
                                                let i = 0;
                                                i < t.cssRules.length;
                                                i++
                                            )
                                                e(
                                                    t.cssRules[i],
                                                    null != s
                                                        ? s
                                                        : t.cssRules[i]
                                                );
                                    };
                                    e(t.sheet, void 0);
                                } else
                                    (this.element = zt.cloneNode()),
                                        null == e || e.prepend(this.element);
                            }
                            Nt.push(this);
                        }
                    }
                    observe(e, t = { subtree: !0, childList: !0 }) {
                        const s = (e) => {
                            e.forEach((e) => {
                                e in this.countOfName
                                    ? this.countOfName[e]++
                                    : ((this.countOfName[e] = 1),
                                      this.findAndInsert(e));
                            });
                        };
                        return (
                            s(e.classList),
                            t.subtree &&
                                e
                                    .querySelectorAll("[class]")
                                    .forEach((e) => s(e.classList)),
                            super.observe(
                                e,
                                Object.assign(Object.assign({}, t), {
                                    attributes: !0,
                                    attributeOldValue: !0,
                                    attributeFilter: ["class"],
                                })
                            ),
                            this
                        );
                    }
                    disconnect() {
                        super.disconnect(),
                            (this.styleOfName = {}),
                            (this.countOfName = {}),
                            (this.styles.length = 0);
                        const e = this.element.sheet;
                        if (e)
                            for (let t = e.cssRules.length - 1; t >= 0; t--)
                                e.deleteRule(t);
                    }
                    static findAndNew(e) {
                        const t = (e) => {
                            for (const t of this.Styles) {
                                const s = t.match(e);
                                if (s) return new t(e, s);
                            }
                        };
                        return e in $t.classes
                            ? $t.classes[e].map(t).filter((e) => e)
                            : t(e);
                    }
                    static find(e) {
                        const t = (e) => {
                            for (const t of this.Styles) {
                                if (t.match(e)) return t;
                            }
                        };
                        return e in $t.classes
                            ? $t.classes[e].map(t).filter((e) => e)
                            : t(e);
                    }
                    static refresh() {
                        for (const e of Nt) e.refresh();
                    }
                    refresh() {
                        if (!this.element) return;
                        const e = zt.cloneNode();
                        this.element.replaceWith(e),
                            (this.element = e),
                            (this.styles.length = 0),
                            (this.styleOfName = {});
                        for (const e in this.countOfName) this.findAndInsert(e);
                    }
                    destroy() {
                        this.disconnect(),
                            Nt.splice(Nt.indexOf(this), 1),
                            this.element.remove();
                    }
                    insert(e) {
                        if (this.styleOfName[e.name]) return;
                        const t = e.text;
                        let s;
                        const i = this.styles.length - 1,
                            r = e.media,
                            n = e.order,
                            a = e.prioritySelectorIndex,
                            c = e.hasWhere,
                            l = (e, t, s, i) => {
                                let r,
                                    l,
                                    o = 0;
                                t && (o = e.findIndex(t)),
                                    s && (l = e.findIndex(s)),
                                    -1 === o && (o = e.length),
                                    (void 0 !== l && -1 !== l) ||
                                        (l = e.length),
                                    (r = e.slice(o, l));
                                for (let e = 0; e < r.length; e++) {
                                    const t = r[e];
                                    if (
                                        !(
                                            -1 === t.prioritySelectorIndex ||
                                            (i && i(t))
                                        ) &&
                                        (t.prioritySelectorIndex < a ||
                                            (t.prioritySelectorIndex === a &&
                                                ((c && !t.hasWhere) ||
                                                    t.order >= n)))
                                    )
                                        return o + e;
                                }
                                return o + r.length;
                            };
                        if (r) {
                            const e = this.styles.findIndex((e) => e.media);
                            if (-1 !== e) {
                                const t = r.features["max-width"],
                                    o = r.features["min-width"];
                                if (t && o) {
                                    const r = t.value - o.value;
                                    for (let t = i; t >= e; t--) {
                                        s = t;
                                        const i = this.styles[t],
                                            n = i.media,
                                            o = n.features["max-width"],
                                            d = n.features["min-width"];
                                        if (!o || !d) {
                                            s++;
                                            break;
                                        }
                                        const h = o.value - d.value;
                                        if (h === r) {
                                            if (c !== i.hasWhere) continue;
                                            if (-1 !== a) {
                                                const i = [this.styles[t]];
                                                for (
                                                    let s = t - 1;
                                                    s >= e;
                                                    s--
                                                ) {
                                                    const e = this.styles[s];
                                                    if (e.hasWhere !== c) break;
                                                    const t = e.media,
                                                        r =
                                                            t.features[
                                                                "max-width"
                                                            ],
                                                        n =
                                                            t.features[
                                                                "min-width"
                                                            ];
                                                    if (
                                                        !r ||
                                                        !n ||
                                                        r.value - n.value !== h
                                                    )
                                                        break;
                                                    i.unshift(this.styles[s]);
                                                }
                                                s = l(
                                                    this.styles,
                                                    (e) =>
                                                        e.media &&
                                                        -1 !==
                                                            e.prioritySelectorIndex &&
                                                        e.media.features[
                                                            "min-width"
                                                        ] &&
                                                        e.media.features[
                                                            "max-width"
                                                        ]
                                                );
                                            }
                                            break;
                                        }
                                        if (h > r) break;
                                    }
                                } else if (o)
                                    for (let t = e; t <= i; t++) {
                                        s = t;
                                        const e = this.styles[t],
                                            r = e.media,
                                            d = r.features["max-width"],
                                            h = r.features["min-width"];
                                        if (d) {
                                            if (h) break;
                                            continue;
                                        }
                                        const u = null == h ? void 0 : h.value;
                                        if (u === o.value) {
                                            if (!c && e.hasWhere) {
                                                s++;
                                                continue;
                                            }
                                            if (-1 !== a)
                                                s = l(
                                                    this.styles,
                                                    (e) => e.media,
                                                    (e) =>
                                                        e.media &&
                                                        -1 !==
                                                            e.prioritySelectorIndex &&
                                                        e.media.features[
                                                            "min-width"
                                                        ] &&
                                                        e.media.features[
                                                            "max-width"
                                                        ],
                                                    (e) =>
                                                        !e.media.features[
                                                            "min-width"
                                                        ] &&
                                                        !e.media.features[
                                                            "max-width"
                                                        ]
                                                );
                                            else
                                                for (let e = t; e <= i; e++) {
                                                    const t = this.styles[e],
                                                        i = t.media,
                                                        r =
                                                            i.features[
                                                                "min-width"
                                                            ];
                                                    if (
                                                        !i.features["max-width"]
                                                    ) {
                                                        if (
                                                            t.hasWhere !== c ||
                                                            r.value !== u ||
                                                            t.order >= n
                                                        )
                                                            break;
                                                        s = e + 1;
                                                    }
                                                }
                                            break;
                                        }
                                        if (u > o.value) break;
                                        s++;
                                    }
                                else if (t)
                                    for (let r = i; r >= e; r--) {
                                        s = r;
                                        const i = this.styles[r],
                                            o = i.media,
                                            d = o.features["max-width"];
                                        if (o.features["min-width"]) continue;
                                        const h = null == d ? void 0 : d.value;
                                        if (!h || h > t.value) {
                                            s++;
                                            break;
                                        }
                                        if (h === t.value) {
                                            if (c && !i.hasWhere) continue;
                                            if (-1 !== a)
                                                s = l(
                                                    this.styles,
                                                    (e) => e.media,
                                                    (e) =>
                                                        e.media &&
                                                        -1 !==
                                                            e.prioritySelectorIndex &&
                                                        e.media.features[
                                                            "min-width"
                                                        ] &&
                                                        e.media.features[
                                                            "max-width"
                                                        ],
                                                    (e) =>
                                                        !e.media.features[
                                                            "min-width"
                                                        ] &&
                                                        !e.media.features[
                                                            "max-width"
                                                        ]
                                                );
                                            else {
                                                const t = [this.styles[r]];
                                                for (
                                                    let s = r - 1;
                                                    s >= e;
                                                    s--
                                                ) {
                                                    const e = this.styles[s],
                                                        i = e.media,
                                                        r =
                                                            i.features[
                                                                "min-width"
                                                            ],
                                                        n =
                                                            i.features[
                                                                "max-width"
                                                            ];
                                                    if (
                                                        !(
                                                            r ||
                                                            (n &&
                                                                n.value === h &&
                                                                e.hasWhere ===
                                                                    c)
                                                        )
                                                    )
                                                        break;
                                                    t.unshift(e);
                                                }
                                                for (
                                                    let e = 0;
                                                    e < t.length;
                                                    e++
                                                ) {
                                                    const i = t[e];
                                                    if (
                                                        !i.media.features[
                                                            "min-width"
                                                        ]
                                                    ) {
                                                        if (i.order >= n) break;
                                                        s =
                                                            r -
                                                            t.length +
                                                            2 +
                                                            e;
                                                    }
                                                }
                                            }
                                            break;
                                        }
                                    }
                            }
                            if (void 0 === s)
                                if (-1 === e) s = i + 1;
                                else if (-1 !== a)
                                    s =
                                        e +
                                        l(
                                            this.styles.slice(e),
                                            void 0,
                                            (e) =>
                                                e.media.features["max-width"] ||
                                                e.media.features["min-width"]
                                        );
                                else if (c) {
                                    let t = e;
                                    for (; t < this.styles.length; t++) {
                                        const e = this.styles[t];
                                        if (
                                            -1 !== e.prioritySelectorIndex ||
                                            !e.hasWhere ||
                                            e.order >= n
                                        ) {
                                            s = t;
                                            break;
                                        }
                                    }
                                    void 0 === s && (s = t);
                                } else
                                    for (let t = e; t <= i; t++) {
                                        s = t;
                                        const e = this.styles[t],
                                            i = e.media;
                                        if (
                                            -1 !== e.prioritySelectorIndex ||
                                            i.features["max-width"] ||
                                            i.features["min-width"]
                                        )
                                            break;
                                        if (e.hasWhere) s++;
                                        else if (e.order >= n) break;
                                    }
                        } else if (-1 === a)
                            if (c)
                                (s = this.styles.findIndex(
                                    (e) =>
                                        !e.hasWhere ||
                                        e.media ||
                                        -1 !== e.prioritySelectorIndex ||
                                        e.order >= n
                                )),
                                    -1 === s && (s = i + 1);
                            else {
                                let e = 0;
                                for (; e < this.styles.length; e++) {
                                    const t = this.styles[e];
                                    if (
                                        t.media ||
                                        (!t.hasWhere &&
                                            (t.order >= n ||
                                                -1 !== t.prioritySelectorIndex))
                                    ) {
                                        s = e;
                                        break;
                                    }
                                }
                                void 0 === s && (s = e);
                            }
                        else s = l(this.styles, void 0, (e) => e.media);
                        try {
                            if (this.element) {
                                const i = this.element.sheet;
                                i.insertRule(t, s), (e.cssRule = i.cssRules[s]);
                            }
                            this.styles.splice(s, 0, e),
                                (this.styleOfName[e.name] = e);
                        } catch (e) {}
                    }
                    delete(e) {
                        const t = this.element.sheet,
                            s = (e) => {
                                const s = this.styleOfName[e];
                                if (
                                    !(
                                        !(null == s ? void 0 : s.cssRule) ||
                                        (e in $t.relations &&
                                            $t.relations[e].some(
                                                (e) => e in this.countOfName
                                            ))
                                    )
                                )
                                    for (
                                        let e = 0;
                                        e < t.cssRules.length;
                                        e++
                                    ) {
                                        t.cssRules[e] === s.cssRule &&
                                            (t.deleteRule(e),
                                            this.styles.splice(e, 1),
                                            delete this.styleOfName[s.name]);
                                    }
                            };
                        if (e in $t.classes)
                            for (const t of $t.classes[e])
                                t in this.countOfName || s(t);
                        else s(e);
                    }
                    findAndInsert(e) {
                        const t = Ct.findAndNew(e);
                        if (Array.isArray(t)) for (const e of t) this.insert(e);
                        else t && this.insert(t);
                    }
                }
                (Ct.Styles = []), Wt && (window.MasterStyleSheet = Ct);
                class Et extends $t {}
                (Et.matches =
                    /^f(ont)?:(thin|extralight|light|regular|medium|semibold|bold|bolder|extrabold|heavy)(?!\|)/),
                    (Et.key = o(m, "weight")),
                    (Et.unit = ""),
                    (Et.values = {
                        thin: 100,
                        extralight: 200,
                        light: 300,
                        regular: 400,
                        medium: 500,
                        semibold: 600,
                        bold: 700,
                        extrabold: 800,
                        heavy: 900,
                    });
                const Mt = "var(--font-";
                class Vt extends $t {}
                (Vt.matches = /^f(ont)?:(mono|sans|serif)(?!\|)/),
                    (Vt.key = o(m, "family")),
                    (Vt.values = {
                        mono: Mt + "mono)",
                        sans: Mt + "sans)",
                        serif: Mt + "serif)",
                    });
                class _t extends $t {}
                (_t.matches =
                    /^f(ont)?:([0-9]|(max|min|calc|clamp)\(.*\))((?!\|).)*$/),
                    (_t.key = o(m, be));
                const Lt = "top",
                    Tt = "bottom",
                    qt = "left",
                    Pt = "right",
                    Zt = "l",
                    Ft = "r",
                    Qt = "t",
                    Ut = "b",
                    Xt = "x",
                    Yt = "y";
                class Bt extends $t {
                    get props() {
                        const e = "m" === this.prefix[0] ? u : f,
                            t = o(e, qt),
                            s = o(e, Pt),
                            i = o(e, Lt),
                            r = o(e, Tt);
                        switch (this.prefix[1]) {
                            case Xt:
                                return { [t]: this, [s]: this };
                            case Yt:
                                return { [i]: this, [r]: this };
                            case Zt:
                                return { [t]: this };
                            case Ft:
                                return { [s]: this };
                            case Qt:
                                return { [i]: this };
                            case Ut:
                                return { [r]: this };
                            default:
                                return { [e]: this };
                        }
                    }
                    get order() {
                        return "p:" === this.prefix || "m:" === this.prefix
                            ? -1
                            : 0;
                    }
                }
                (Bt.id = "spacing"), (Bt.matches = /^[pm][xytblr]?:./);
                class Dt extends $t {}
                (Dt.matches = /^w:./), (Dt.key = F), (Dt.values = He);
                class Gt extends $t {}
                (Gt.matches = /^h:./), (Gt.key = Z), (Gt.values = He);
                class Ht extends $t {}
                (Ht.matches = /^min-w:./), (Ht.key = Re), (Ht.values = He);
                class Jt extends $t {}
                (Jt.matches = /^min-h:./), (Jt.key = We), (Jt.values = He);
                class Kt extends $t {}
                (Kt.matches = /^ls:./), (Kt.key = $e), (Kt.unit = "em");
                const es = o("subpixel", Se),
                    ts = o("-webkit-font", je),
                    ss = o("-moz-osxfont", je);
                class is extends $t {
                    get props() {
                        const e = {};
                        switch (this.value) {
                            case es:
                                e[ts] = e[ss] = Object.assign(
                                    Object.assign({}, this),
                                    { value: ge }
                                );
                                break;
                            case Se:
                                (e[ts] = Object.assign(
                                    Object.assign({}, this),
                                    { value: Se }
                                )),
                                    (e[ss] = Object.assign(
                                        Object.assign({}, this),
                                        { value: "grayscale" }
                                    ));
                        }
                        return e;
                    }
                }
                (is.id = "fontSmoothing"),
                    (is.matches =
                        /^f(ont)?:(antialiased|subpixel-antialiased)(?!\|)/),
                    (is.unit = "");
                class rs extends $t {}
                (rs.matches = /^f(ont)?:(normal|italic|oblique)(?!\|)/),
                    (rs.key = o(m, Oe)),
                    (rs.unit = "deg"),
                    (rs.semantics = { italic: "italic", oblique: "oblique" });
                class ns extends $t {}
                (ns.matches =
                    /^f(ont)?:(ordinal|slashed-zero|lining-nums|oldstyle-nums|proportional-nums|tabular-nums|diagonal-fractions|stacked-fractions)(?!\|)/),
                    (ns.key = o(m, "variant", "numeric"));
                class as extends $t {}
                (as.matches = /^lh:./), (as.key = o(ye, Z)), (as.unit = "");
                class cs extends $t {}
                (cs.matches = /^(object|obj):(contain|cover|fill|scale-down)/),
                    (cs.key = o(ke, "fit"));
                class ls extends $t {}
                (ls.matches = /^(object|obj):(top|bottom|right|left|center)/),
                    (ls.key = o(ke, ve));
                class os extends $t {}
                (os.matches =
                    /^t(ext)?:(justify|center|left|right|start|end)(?!\|)/),
                    (os.key = o(oe, de));
                class ds extends $t {
                    constructor() {
                        super(...arguments), (this.order = -1);
                    }
                }
                (ds.matches = /^t(ext)?:(underline|line-through|overline)/),
                    (ds.key = o(oe, pe)),
                    (ds.colorful = !0);
                class hs extends $t {}
                (hs.matches =
                    /^t(ext)?:(uppercase|lowercase|capitalize)(?!\|)/),
                    (hs.key = o(oe, he)),
                    (hs.semantics = {
                        uppercase: "uppercase",
                        lowercase: "lowercase",
                        capitalize: "capitalize",
                    });
                class us extends $t {}
                (us.matches = /^(?:v|vertical):./), (us.key = o(ue, de));
                class fs extends $t {
                    get props() {
                        return {
                            overflow: Object.assign(Object.assign({}, this), {
                                value: re,
                            }),
                            display: Object.assign(Object.assign({}, this), {
                                value: "-webkit-box",
                            }),
                            "overflow-wrap": Object.assign(
                                Object.assign({}, this),
                                { value: o(xe, fe) }
                            ),
                            "text-overflow": Object.assign(
                                Object.assign({}, this),
                                { value: "ellipsis" }
                            ),
                            "-webkit-box-orient": Object.assign(
                                Object.assign({}, this),
                                { value: ue }
                            ),
                            "-webkit-line-clamp": this,
                        };
                    }
                }
                (fs.id = "lines"), (fs.matches = /^lines:./), (fs.unit = "");
                class ms extends $t {}
                (ms.matches = /^transform:((top|bottom|right|left|center)|\d)/),
                    (ms.key = o(he, H)),
                    (ms.unit = "px");
                class ps extends $t {}
                (ps.matches = /^transform:(flat|preserve-3d)(?!\|)/),
                    (ps.key = o(he, Oe));
                class xs extends $t {}
                (xs.matches =
                    /^transform:(content|border|fill|stroke|view)(?!\|)/),
                    (xs.key = o(he, ne)),
                    (xs.values = {
                        content: o(N, ne),
                        border: o(d, ne),
                        fill: o(ae, ne),
                        stroke: o(I, ne),
                        view: o(Xe, ne),
                    });
                class bs extends $t {
                    get parseValue() {
                        return this.value.replace(
                            /(translate|scale|skew|rotate|perspective|matrix)(3d|[XYZ])?\((.*?)\)/g,
                            (e, t, s, i) => {
                                let r, n;
                                switch (t) {
                                    case "translate":
                                        r = we;
                                        break;
                                    case "skew":
                                        r = ee;
                                        break;
                                    case K:
                                        "3d" === s && (n = !0), (r = ee);
                                        break;
                                    default:
                                        return e;
                                }
                                const a = i.split(",");
                                return e.replace(
                                    i,
                                    a
                                        .map((e, t) => {
                                            if (n && a.length - 1 !== t)
                                                return e;
                                            return Number.isNaN(+e)
                                                ? e
                                                : e / (r === we ? 16 : 1) + r;
                                        })
                                        .join(",")
                                );
                            }
                        );
                    }
                }
                (bs.matches =
                    /^(translate|scale|skew|rotate|perspective|matrix)(3d|[XYZ])?\(/),
                    (bs.key = he),
                    (bs.unit = "");
                class gs extends $t {
                    constructor() {
                        super(...arguments), (this.order = -1);
                    }
                }
                (gs.symbol = "~"), (gs.key = G);
                class ys extends $t {}
                (ys.matches = /^~delay:./),
                    (ys.key = o(G, J)),
                    (ys.unit = "ms");
                class ks extends $t {}
                (ks.matches = /^~duration:./),
                    (ks.key = o(G, se)),
                    (ks.unit = "ms");
                class vs extends $t {}
                (vs.matches = /^~property:./), (vs.key = o(G, "property"));
                class ws extends $t {}
                (ws.matches = /^~easing:./), (ws.key = o(G, te));
                class Os extends $t {}
                (Os.matches = /^max-h:./), (Os.key = ze), (Os.values = He);
                class js extends $t {}
                (js.matches = /^max-w:./), (js.key = Ie), (js.values = He);
                class Ss extends $t {}
                (Ss.matches = /^d:./),
                    (Ss.key = ie),
                    (Ss.semantics = {
                        hidden: le,
                        hide: le,
                        block: X,
                        table: Ae,
                        flex: B,
                        grid: D,
                        contents: "contents",
                        inline: Y,
                        "inline-block": o(Y, X),
                        "inline-flex": o(Y, B),
                        "inline-grid": o(Y, D),
                        "inline-table": o(Y, Ae),
                        "table-cell": o(Ae, "cell"),
                        "table-caption": o(Ae, "caption"),
                        "flow-root": o(b, "root"),
                        "list-item": o(Ee, Me),
                        "table-row": o(Ae, w),
                        "table-column": o(Ae, y),
                        "table-row-group": o(Ae, w, Ce),
                        "table-column-group": o(Ae, y, Ce),
                        "table-header-group": o(Ae, "header", Ce),
                        "table-footer-group": o(Ae, "footer", Ce),
                    });
                class Ns extends $t {}
                (Ns.matches = /^box:(content|border)(?!\|)/),
                    (Ns.key = o(ne, "sizing")),
                    (Ns.values = { content: o(N, ne), border: o(d, ne) });
                class $s extends $t {}
                ($s.key = "opacity"), ($s.unit = "");
                class Is extends $t {}
                (Is.key = "visibility"),
                    (Is.semantics = { visible: "visible", invisible: re });
                class Rs extends $t {}
                Rs.key = "clear";
                class zs extends $t {}
                zs.key = "float";
                class Ws extends $t {}
                (Ws.key = "isolation"), (Ws.semantics = { isolate: "isolate" });
                class As extends $t {
                    get props() {
                        if (this.prefix)
                            switch (this.prefix.slice(-2, -1)) {
                                case P:
                                    return { "overflow-x": this };
                                case q:
                                    return { "overflow-y": this };
                            }
                        return { overflow: this };
                    }
                    get order() {
                        if (this.prefix)
                            switch (this.prefix.slice(-2, -1)) {
                                case P:
                                case q:
                                    return 0;
                            }
                        return -1;
                    }
                }
                (As.id = "overflow"),
                    (As.matches =
                        /^overflow(-x|-y)?:(?:visible|overlay|hidden|scroll|auto|clip|inherit|initial|revert|revert-layer|unset|\$|var)/),
                    (As.semantics = { overflow: "visible" });
                class Cs extends $t {
                    get props() {
                        switch (this.prefix.slice(-2, -1)) {
                            case P:
                                return { [o(T, P)]: this };
                            case q:
                                return { [o(T, q)]: this };
                            default:
                                return { [T]: this };
                        }
                    }
                }
                (Cs.id = "overscrollBehavior"),
                    (Cs.matches = /^overscroll-behavior(?:-[xy])?:/);
                class Es extends $t {}
                (Es.matches = /^z:./), (Es.key = "z-index"), (Es.unit = "");
                class Ms extends $t {}
                (Ms.matches = /^\@delay:./),
                    (Ms.key = o(M, J)),
                    (Ms.unit = "ms");
                class Vs extends $t {}
                (Vs.matches = /^\@direction:./), (Vs.key = o(M, V));
                class _s extends $t {}
                (_s.matches = /^\@fill-mode:./), (_s.key = o(M, ae, _));
                class Ls extends $t {}
                (Ls.matches = /^\@iteration-count:./),
                    (Ls.key = o(M, "iteration", "count")),
                    (Ls.unit = "");
                class Ts extends $t {}
                (Ts.matches = /^\@name:./), (Ts.key = o(M, "name"));
                class qs extends $t {}
                (qs.matches = /^\@play-state:./), (qs.key = o(M, "play-state"));
                class Ps extends $t {}
                (Ps.matches = /^\@easing:./), (Ps.key = o(M, te));
                class Zs extends $t {
                    constructor() {
                        super(...arguments), (this.order = -1);
                    }
                }
                (Zs.symbol = "@"), (Zs.key = M), (Zs.unit = "");
                function Fs(e, t, s = "") {
                    s && (s = "-" + s);
                    const i = "border-left" + s,
                        r = "border-right" + s,
                        n = "border-top" + s,
                        a = "border-bottom" + s;
                    switch (/^b(order)?-?(.)?/.exec(e)[2]) {
                        case Xt:
                            return { [i]: t, [r]: t };
                        case Yt:
                            return { [n]: t, [a]: t };
                        case Zt:
                            return { [i]: t };
                        case Ft:
                            return { [r]: t };
                        case Qt:
                            return { [n]: t };
                        case Ut:
                            return { [a]: t };
                        default:
                            return { [d + s]: t };
                    }
                }
                class Qs extends $t {
                    get props() {
                        return Fs(this.prefix, this, C);
                    }
                    get order() {
                        return this.prefix === o(d, C) + ":" ||
                            "b:" === this.prefix ||
                            "border:" === this.prefix
                            ? -1
                            : 0;
                    }
                }
                (Qs.id = "borderColor"),
                    (Qs.matches = /^border(-(left|right|top|bottom))?-color:./),
                    (Qs.colorStarts =
                        "b([xytblr]|(order(-(left|right|top|bottom))?))?:"),
                    (Qs.colorful = !0),
                    (Qs.values = { current: p });
                const Us = o(d, Lt, qt, h),
                    Xs = o(d, Lt, Pt, h),
                    Ys = o(d, Tt, qt, h),
                    Bs = o(d, Tt, Pt, h),
                    Ds = o(d, h),
                    Gs = [Us, Xs, Ys, Bs];
                class Hs extends $t {
                    get props() {
                        var e;
                        if (this.prefix) {
                            let e = "";
                            const t = this.prefix.split("-");
                            if (t.length > 1)
                                for (let s = 1; s < t.length - 1; s++)
                                    e += t[s][0];
                            else e = this.prefix.slice(1, -1);
                            switch (e) {
                                case Qt:
                                    return { [Us]: this, [Xs]: this };
                                case "tl":
                                case "lt":
                                    return { [Us]: this };
                                case "rt":
                                case "tr":
                                    return { [Xs]: this };
                                case Ut:
                                    return { [Ys]: this, [Bs]: this };
                                case "bl":
                                case "lb":
                                    return { [Ys]: this };
                                case "br":
                                case "rb":
                                    return { [Bs]: this };
                                case Zt:
                                    return { [Us]: this, [Ys]: this };
                                case Ft:
                                    return { [Xs]: this, [Bs]: this };
                                default:
                                    return { [Ds]: this };
                            }
                        }
                        const t =
                            null === (e = this.prefix) || void 0 === e
                                ? void 0
                                : e.slice(0, -1);
                        return { [Gs.includes(t) ? t : Ds]: this };
                    }
                    get order() {
                        return this.prefix === o(d, h) + ":" ||
                            "r:" === this.prefix
                            ? -1
                            : 0;
                    }
                }
                (Hs.id = "borderRadius"),
                    (Hs.matches =
                        /^((r[tblr]?[tblr]?|border(-(top|bottom)-(left|right))?-radius):.)/),
                    (Hs.semantics = { rounded: "1e9em", round: "50%" });
                class Js extends $t {
                    get props() {
                        return Fs(this.prefix, this, Oe);
                    }
                    get order() {
                        return this.prefix === o(d, Oe) + ":" ||
                            "b:" === this.prefix ||
                            "border:" === this.prefix
                            ? -1
                            : 0;
                    }
                }
                (Js.id = "borderStyle"),
                    (Js.matches =
                        /^(border(-(left|right|top|bottom))?-style:.|b([xytblr]|order(-(left|right|top|bottom))?)?:(none|hidden|dotted|dashed|solid|double|groove|ridge|inset|outset)(?!\|))/);
                class Ks extends $t {
                    get props() {
                        return Fs(this.prefix, this, F);
                    }
                    get order() {
                        return this.prefix === o(d, F) + ":" ||
                            "b:" === this.prefix ||
                            "border:" === this.prefix
                            ? -1
                            : 0;
                    }
                }
                (Ks.id = "borderWidth"),
                    (Ks.matches =
                        /^(border(-(left|right|top|bottom))?-width:.|b([xytblr]|order(-(left|right|top|bottom))?)?:(([0-9]|(max|min|calc|clamp)\(.*\))|(max|min|calc|clamp)\(.*\))((?!\|).)*$)/);
                class ei extends $t {
                    get props() {
                        return Fs(this.prefix, this);
                    }
                    get order() {
                        return "border:" === this.prefix || "b:" === this.prefix
                            ? -2
                            : -1;
                    }
                }
                (ei.id = "border"),
                    (ei.matches =
                        /^b([xytblr]?|order(-(left|right|top|bottom))?):./),
                    (ei.colorful = !0);
                class ti extends $t {}
                (ti.matches = /^(bg|background):(fixed|local|scroll)(?!\|)/),
                    (ti.key = o(W, "attachment"));
                class si extends $t {}
                si.key = o(W, z, _);
                class ii extends $t {
                    get props() {
                        return {
                            "-webkit-background-clip": this,
                            "background-clip": this,
                        };
                    }
                }
                (ii.matches = /^(bg|background):text(?!\|)/),
                    (ii.key = o(W, ce)),
                    (ii.values = {
                        content: o(N, ne),
                        border: o(d, ne),
                        padding: o(f, ne),
                    });
                class ri extends $t {}
                (ri.matches = /^(bg|background):transparent(?!\|)/),
                    (ri.colorStarts = "(bg|background):"),
                    (ri.key = o(W, C)),
                    (ri.unit = ""),
                    (ri.colorful = !0),
                    (ri.values = { current: p });
                class ni extends $t {}
                (ni.matches =
                    /^(bg|background):(content|border|padding)(?!\|)/),
                    (ni.key = o(W, H)),
                    (ni.values = {
                        content: o(N, ne),
                        border: o(d, ne),
                        padding: o(f, ne),
                    });
                class ai extends $t {}
                (ai.matches =
                    /^(bg|background):(top|bottom|right|left|center)(?!\|)/),
                    (ai.key = o(W, ve)),
                    (ai.unit = "px");
                class ci extends $t {}
                (ci.matches =
                    /^(bg|background):(space|round|repeat|no-repeat|repeat-x|repeat-y)(?![;a-zA-Z])/),
                    (ci.key = o(W, A));
                class li extends $t {}
                (li.matches =
                    /^(bg|background):((auto|cover|contain)(?!\|)|\.?\d((?!\|).)*$)/),
                    (li.key = o(W, be));
                class oi extends $t {}
                (oi.matches =
                    /^(bg|background):(url|linear-gradient|radial-gradient|repeating-linear-gradient|repeating-radial-gradient|conic-gradient)\(.*\)((?!\|).)*$/),
                    (oi.key = o(W, $)),
                    (oi.colorful = !0);
                class di extends $t {
                    constructor() {
                        super(...arguments), (this.order = -1);
                    }
                }
                (di.matches = /^bg:./), (di.key = W), (di.colorful = !0);
                class hi extends $t {}
                (hi.matches = /^blend:./), (hi.key = o("mix", z, _));
                class ui extends $t {}
                (ui.key = ve),
                    (ui.values = { abs: "absolute", rel: "relative" }),
                    (ui.semantics = {
                        static: "static",
                        fixed: "fixed",
                        abs: "absolute",
                        rel: "relative",
                        sticky: "sticky",
                    });
                class fi extends $t {
                    get props() {
                        const e = this.prefix.slice(0, -1);
                        switch (e) {
                            case Lt:
                            case qt:
                            case Pt:
                            case Tt:
                                return { [e]: this };
                            case "center":
                                return {
                                    left: this,
                                    right: this,
                                    "margin-left": Object.assign(
                                        Object.assign({}, this),
                                        { unit: "" }
                                    ),
                                    "margin-right": Object.assign(
                                        Object.assign({}, this),
                                        { unit: "" }
                                    ),
                                };
                            case "middle":
                                return {
                                    top: this,
                                    bottom: this,
                                    "margin-top": Object.assign(
                                        Object.assign({}, this),
                                        { unit: "" }
                                    ),
                                    "margin-bottom": Object.assign(
                                        Object.assign({}, this),
                                        { unit: "" }
                                    ),
                                };
                        }
                    }
                }
                function mi(e, t) {
                    let s = "",
                        i = 0;
                    return (
                        (function r(n, a) {
                            let c = "";
                            const l = a ? t(a) : "",
                                o = () => {
                                    c &&
                                        ((s +=
                                            !l || Number.isNaN(+c)
                                                ? c
                                                : +c / (l === we ? 16 : 1) + l),
                                        (c = ""));
                                };
                            for (; i < e.length; i++) {
                                const t = e[i];
                                if (
                                    t === n &&
                                    ("'" !== n || ")" === e[i + 1])
                                ) {
                                    o(), (s += t);
                                    break;
                                }
                                "," === t || " " === t
                                    ? (o(), (s += t))
                                    : c || "'" !== t
                                    ? c && "(" === t
                                        ? ((s += c + t),
                                          i++,
                                          r(")", c),
                                          (c = ""))
                                        : (c += t)
                                    : ((s += t), i++, r(t), (c = ""));
                            }
                            o();
                        })(),
                        s
                    );
                }
                fi.matches = /^(top|left|right|bottom|center|middle):./;
                class pi extends $t {
                    get props() {
                        return {
                            "backdrop-filter": this,
                            "-webkit-backdrop-filter": this,
                        };
                    }
                    get parseValue() {
                        return mi(this.value, (e) => {
                            switch (e) {
                                case De:
                                case o(Ge, j):
                                    return we;
                                case o("hue", K):
                                    return ee;
                            }
                            return "";
                        });
                    }
                }
                (pi.matches = /^bd:./),
                    (pi.key = o("backdrop", R)),
                    (pi.colorful = !0);
                class xi extends $t {}
                (xi.key = ae),
                    (xi.colorStarts = "fill:"),
                    (xi.colorful = !0),
                    (xi.values = { current: p });
                class bi extends $t {}
                (bi.key = I), (bi.colorful = !0);
                class gi extends $t {}
                (gi.matches =
                    /^stroke:([0-9]|(max|min|calc|clamp)\(.*\))((?!\|).)*$/),
                    (gi.key = o(I, F));
                class yi extends $t {
                    get parseValue() {
                        return mi(this.value, (e) => {
                            switch (e) {
                                case De:
                                case o(Ge, j):
                                    return we;
                                case o("hue", K):
                                    return ee;
                            }
                            return "";
                        });
                    }
                }
                (yi.matches =
                    /^(blur|brightness|contrast|drop-shadow|grayscale|hue-rotate|invert|opacity|saturate|sepia)\(/),
                    (yi.key = R),
                    (yi.colorful = !0);
                class ki extends $t {}
                ki.key = "cursor";
                class vi extends $t {}
                (vi.key = o("pointer", "events")),
                    (vi.semantics = { untouchable: "none" });
                class wi extends $t {}
                wi.key = "resize";
                class Oi extends $t {}
                Oi.key = o("touch", "action");
                class ji extends $t {
                    get props() {
                        return { "user-drag": this, "-webkit-user-drag": this };
                    }
                }
                ji.key = o(S, "drag");
                class Si extends $t {
                    get props() {
                        return {
                            "user-select": this,
                            "-webkit-user-select": this,
                        };
                    }
                }
                Si.key = o(S, "select");
                class Ni extends $t {}
                (Ni.matches = /^s(?:hadow)?:./),
                    (Ni.key = o(ne, j)),
                    (Ni.colorful = !0);
                class $i extends $t {}
                ($i.key = o(oe, j)), ($i.colorful = !0);
                class Ii extends $t {
                    get props() {
                        return {
                            "font-size": this,
                            "line-height": Object.assign(
                                Object.assign({}, this),
                                {
                                    value:
                                        this.unit === we
                                            ? this.value + 0.375 + this.unit
                                            : "calc(" +
                                              this.value +
                                              this.unit +
                                              " + .375rem)",
                                    unit: "",
                                }
                            ),
                        };
                    }
                }
                (Ii.id = "textSize"),
                    (Ii.matches =
                        /^t(ext)?:([0-9]|(max|min|calc|clamp)\(.*\))((?!\|).)*$/);
                class Ri extends $t {}
                (Ri.key = o(fe, xe)), (Ri.unit = "");
                class zi extends $t {
                    get props() {
                        return {
                            [ie]: Object.assign(Object.assign({}, this), {
                                value: D,
                            }),
                            [o(D, g, k)]: Object.assign(
                                Object.assign({}, this),
                                {
                                    value:
                                        "repeat(" +
                                        this.value +
                                        x +
                                        U +
                                        "max(0" +
                                        ",1fr))",
                                }
                            ),
                        };
                    }
                }
                (zi.matches = /^grid-cols:./),
                    (zi.key = o(D, k)),
                    (zi.unit = "");
                class Wi extends $t {
                    get props() {
                        return {
                            [ie]: Object.assign(Object.assign({}, this), {
                                value: D,
                            }),
                            [o(D, ge, b)]: Object.assign(
                                Object.assign({}, this),
                                { value: y }
                            ),
                            [o(D, g, O)]: Object.assign(
                                Object.assign({}, this),
                                {
                                    value:
                                        "repeat(" +
                                        this.value +
                                        x +
                                        U +
                                        "max(0" +
                                        ",1fr))",
                                }
                            ),
                        };
                    }
                }
                (Wi.key = o(D, O)), (Wi.unit = "");
                class Ai extends $t {
                    constructor() {
                        super(...arguments), (this.order = -1);
                    }
                    get props() {
                        switch (this.prefix[4]) {
                            case P:
                                return { [o(y, v)]: this };
                            case q:
                                return { [o(w, v)]: this };
                            default:
                                return { [v]: this };
                        }
                    }
                }
                (Ai.id = "gap"), (Ai.matches = /^gap(-x|-y)?:./);
                class Ci extends $t {}
                Ci.key = o(fe, Ne);
                class Ei extends $t {
                    get props() {
                        return { ["--" + this.prefix.slice(1, -1)]: this };
                    }
                }
                (Ei.id = "variable"), (Ei.matches = /^\$.+:./), (Ei.unit = "");
                class Mi extends $t {}
                (Mi.matches = /^aspect:./),
                    (Mi.key = o("aspect", "ratio")),
                    (Mi.unit = ""),
                    (Mi.semantics = { square: "1/1", video: "16/9" });
                class Vi extends $t {
                    get props() {
                        return {
                            "box-decoration-break": this,
                            "-webkit-box-decoration-break": this,
                        };
                    }
                }
                (Vi.matches = /^box:(slice|clone)(?!\|)/),
                    (Vi.key = o(ne, pe, xe));
                class _i extends $t {}
                _i.key = o(xe, "after");
                class Li extends $t {}
                Li.key = o(xe, "before");
                class Ti extends $t {}
                Ti.key = o(xe, "inside");
                class qi extends $t {}
                (qi.key = o(B, "shrink")), (qi.unit = "");
                class Pi extends $t {}
                (Pi.matches = /^flex:((row|col|column)(-reverse)?)(?!\|)/),
                    (Pi.key = o(B, V)),
                    (Pi.values = { col: y, "col-reverse": o(y, "reverse") });
                class Zi extends $t {}
                (Zi.key = o(B, "grow")), (Zi.unit = "");
                class Fi extends $t {}
                (Fi.matches = /^flex:(wrap(-reverse)?|nowrap)(?!\|)/),
                    (Fi.key = o(B, "wrap"));
                class Qi extends $t {}
                (Qi.key = o(B, "basis")), (Qi.values = He);
                class Ui extends $t {
                    constructor() {
                        super(...arguments), (this.order = -1);
                    }
                }
                (Ui.key = B), (Ui.unit = "");
                const Xi = "999999";
                class Yi extends $t {}
                (Yi.matches = /^o:./),
                    (Yi.key = "order"),
                    (Yi.values = { first: "-999999", last: Xi }),
                    (Yi.unit = "");
                class Bi extends $t {
                    constructor() {
                        super(...arguments), (this.order = -1);
                    }
                    get parseValue() {
                        return "span" === this.prefix.slice(-5, -1) &&
                            "auto" !== this.value
                            ? "span " + this.value + "/" + "span " + this.value
                            : this.value;
                    }
                }
                (Bi.matches = /^grid-col(?:umn)?(?:-span)?:./),
                    (Bi.key = o(D, y)),
                    (Bi.unit = "");
                class Di extends $t {}
                (Di.matches = /^col-span:./), (Di.key = o(y, Ve));
                class Gi extends $t {
                    constructor() {
                        super(...arguments), (this.order = -1);
                    }
                    get parseValue() {
                        return "span" === this.prefix.slice(-5, -1) &&
                            "auto" !== this.value
                            ? "span " + this.value + "/" + "span " + this.value
                            : this.value;
                    }
                }
                (Gi.matches = /^grid-row-span:./),
                    (Gi.key = o(D, w)),
                    (Gi.unit = "");
                class Hi extends $t {}
                (Hi.matches = /^(font-color:.|font:current)/),
                    (Hi.colorStarts = "(?:f(ont)?|color):"),
                    (Hi.colorful = !0),
                    (Hi.key = C),
                    (Hi.unit = ""),
                    (Hi.values = { current: p });
                class Ji extends $t {}
                (Ji.matches = /^ac:./), (Ji.key = o(de, N));
                class Ki extends $t {}
                (Ki.matches = /^ai:./), (Ki.key = o(de, Le));
                class er extends $t {}
                (er.matches = /^as:./), (er.key = o(de, Te));
                class tr extends $t {}
                (tr.matches = /^grid-auto-cols:./),
                    (tr.key = o(D, ge, k)),
                    (tr.values = { min: o(U, N), max: o(Q, N) });
                class sr extends $t {}
                (sr.matches = /^grid-flow:./), (sr.key = o(D, ge, b));
                class ir extends $t {}
                (ir.key = o(D, ge, O)),
                    (ir.values = { min: o(U, N), max: o(Q, N) });
                class rr extends $t {}
                (rr.matches = /^jc:./), (rr.key = o(_e, N));
                class nr extends $t {}
                (nr.matches = /^ji:./), (nr.key = o(_e, Le));
                class ar extends $t {}
                (ar.matches = /^js:./), (ar.key = o(_e, Te));
                class cr extends $t {
                    constructor() {
                        super(...arguments), (this.order = -1);
                    }
                }
                cr.key = o(qe, N);
                class lr extends $t {
                    constructor() {
                        super(...arguments), (this.order = -1);
                    }
                }
                lr.key = o(qe, Le);
                class or extends $t {
                    constructor() {
                        super(...arguments), (this.order = -1);
                    }
                }
                or.key = o(qe, Te);
                class dr extends $t {
                    get props() {
                        return { [this.prefix.slice(0, -1)]: this };
                    }
                    get order() {
                        return "padding:" === this.prefix ? -1 : 0;
                    }
                }
                (dr.id = "padding"),
                    (dr.matches = /^padding(?:-(?:left|right|top|bottom))?:./);
                class hr extends $t {
                    get props() {
                        return { [this.prefix.slice(0, -1)]: this };
                    }
                    get order() {
                        return "margin:" === this.prefix ? -1 : 0;
                    }
                }
                (hr.id = "margin"),
                    (hr.matches = /^margin(-(left|right|top|bottom))?:./);
                class ur extends $t {}
                (ur.matches =
                    /^(text-(overflow|ovf):.|t(ext)?:(ellipsis|clip)(?!\|))/),
                    (ur.key = o(oe, "overflow"));
                class fr extends $t {}
                (fr.matches = /^list-style:(inside|outside)(?!\|)/),
                    (fr.key = o(Ee, Oe, ve));
                class mr extends $t {}
                (mr.matches = /^list-style:(disc|decimal)(?!\|)/),
                    (mr.key = o(Ee, Oe, Pe));
                class pr extends $t {
                    constructor() {
                        super(...arguments), (this.order = -1);
                    }
                }
                pr.key = o(Ee, Oe);
                class xr extends $t {}
                (xr.key = o(oe, pe, C)),
                    (xr.colorStarts = "text-decoration:"),
                    (xr.colorful = !0),
                    (xr.values = { current: p });
                class br extends $t {}
                (br.matches =
                    /^t(ext)?:(solid|double|dotted|dashed|wavy)(?!\|)/),
                    (br.key = o(oe, pe, Oe));
                class gr extends $t {}
                (gr.matches =
                    /^text-decoration:(from-font(?!\|)|([0-9]|(max|min|calc|clamp)\(.*\))((?!\|).)*$)/),
                    (gr.key = o(oe, pe, "thickness")),
                    (gr.unit = "em");
                class yr extends $t {}
                yr.key = o(oe, "indent");
                class kr extends $t {}
                kr.key = N;
                class vr extends $t {}
                (vr.key = o(Fe, C)),
                    (vr.colorStarts = "outline:"),
                    (vr.colorful = !0),
                    (vr.values = { current: p });
                class wr extends $t {}
                wr.key = o(Fe, Ze);
                class Or extends $t {}
                (Or.matches =
                    /^outline:(none|dotted|dashed|solid|double|groove|ridge|inset|outset)(?!\|)/),
                    (Or.key = o(Fe, Oe));
                class jr extends $t {}
                (jr.matches =
                    /^outline:(medium|thick|thin|[0-9]|(max|min|calc|clamp)\(.*\))((?!\|).)*$/),
                    (jr.key = o(Fe, F));
                class Sr extends $t {
                    constructor() {
                        super(...arguments), (this.order = -1);
                    }
                }
                (Sr.key = Fe), (Sr.colorful = !0);
                class Nr extends $t {}
                (Nr.matches = /^b(order)?:(collapse|separate)(?!\|)/),
                    (Nr.key = o(d, "collapse"));
                class $r extends $t {}
                $r.key = o(d, Ne);
                class Ir extends $t {}
                Ir.key = o(Ae, "layout");
                class Rr extends $t {}
                (Rr.key = o("accent", C)),
                    (Rr.colorStarts = "accent:"),
                    (Rr.colorful = !0);
                class zr extends $t {}
                zr.key = "appearance";
                class Wr extends $t {}
                (Wr.key = o("caret", C)),
                    (Wr.matches = /^caret:transparent(?!\|)/),
                    (Wr.colorStarts = "caret:"),
                    (Wr.colorful = !0),
                    (Wr.values = { current: p });
                class Ar extends $t {}
                Ar.key = o(E, L);
                class Cr extends $t {
                    get props() {
                        if ("m" !== this.prefix.slice(-3, -2))
                            return {
                                [this.prefix
                                    .replace(/-m(?!argin)/, "-margin")
                                    .slice(0, -1)]: this,
                            };
                        {
                            const e = o(E, u) + "-",
                                t = e + qt,
                                s = e + Pt,
                                i = e + Lt,
                                r = e + Tt;
                            switch (this.prefix.slice(-2, -1)) {
                                case Xt:
                                    return { [t]: this, [s]: this };
                                case Yt:
                                    return { [i]: this, [r]: this };
                                case Zt:
                                    return { [t]: this };
                                case Ft:
                                    return { [s]: this };
                                case Qt:
                                    return { [i]: this };
                                case Ut:
                                    return { [r]: this };
                            }
                        }
                    }
                    get order() {
                        return this.prefix === o(E, u) + ":" ||
                            this.prefix === o(E, "m:")
                            ? -1
                            : 0;
                    }
                }
                (Cr.id = "scrollMargin"),
                    (Cr.matches =
                        /^scroll-m([xytblr]|argin(-(top|bottom|left|right))?)?:./);
                class Er extends $t {
                    get props() {
                        if ("p" !== this.prefix.slice(-3, -2))
                            return {
                                [this.prefix
                                    .replace(/-p(?!adding)/, "-padding")
                                    .slice(0, -1)]: this,
                            };
                        {
                            const e = o(E, f) + "-",
                                t = e + qt,
                                s = e + Pt,
                                i = e + Lt,
                                r = e + Tt;
                            switch (this.prefix.slice(-2, -1)) {
                                case Xt:
                                    return { [t]: this, [s]: this };
                                case Yt:
                                    return { [i]: this, [r]: this };
                                case Zt:
                                    return { [t]: this };
                                case Ft:
                                    return { [s]: this };
                                case Qt:
                                    return { [i]: this };
                                case Ut:
                                    return { [r]: this };
                            }
                        }
                    }
                    get order() {
                        return this.prefix === o(E, f) + ":" ||
                            this.prefix === o(E, "p:")
                            ? -1
                            : 0;
                    }
                }
                (Er.id = "scrollPadding"),
                    (Er.matches =
                        /^scroll-p([xytblr]|adding(-(top|bottom|left|right))?)?:./);
                class Mr extends $t {}
                (Mr.matches = /^scroll-snap:(start|end|center)/),
                    (Mr.key = o(E, Qe, de));
                class Vr extends $t {}
                (Vr.matches = /^scroll-snap:(normal|always)(?!\|)/),
                    (Vr.key = o(E, Qe, "stop"));
                class _r extends $t {}
                (_r.matches =
                    /^scroll-snap:(([xy]|block|inline|both)(\|(proximity|mandatory))?)(?!\|)/),
                    (_r.key = o(E, Qe, Pe));
                class Lr extends $t {}
                Lr.key = "will-change";
                class Tr extends $t {}
                Tr.key = o(oe, "underline", Ze);
                class qr extends $t {
                    get props() {
                        return { [this.prefix.slice(0, -1)]: this };
                    }
                }
                (qr.matches = /^(?:top|bottom|left|right):./),
                    (qr.key = "inset");
                class Pr extends $t {
                    constructor() {
                        super(...arguments), (this.order = -1);
                    }
                }
                (Pr.matches = /^(columns|cols):./),
                    (Pr.key = k),
                    (Pr.unit = "");
                class Zr extends $t {}
                (Zr.key = o("white", me)), (Zr.unit = "");
                class Fr extends $t {}
                (Fr.matches =
                    /^t(ext)?:(mixed|upright|sideways-right|sideways|use-glyph-orientation)(?!\|)/),
                    (Fr.key = o(oe, "orientation"));
                class Qr extends $t {}
                (Qr.matches = /^writing:./), (Qr.key = o("writing", _));
                class Ur extends $t {}
                Ur.key = "contain";
                class Xr extends $t {}
                (Xr.matches = /^\@duration:./),
                    (Xr.key = o(M, se)),
                    (Xr.unit = "ms");
                class Yr extends $t {}
                (Yr.matches =
                    /^t(ext)?:(optimizeSpeed|optimizeLegibility|geometricPrecision)(?!\|)/),
                    (Yr.key = o(oe, "rendering"));
                class Br extends $t {}
                Br.key = V;
                class Dr extends $t {}
                (Dr.matches =
                    /^t(ext)?:(none|underline|overline|line-through)(?!\|)/),
                    (Dr.key = o(oe, pe, ye));
                class Gr extends $t {}
                (Gr.matches = /^grid-col-start:./),
                    (Gr.key = o(D, y, Be)),
                    (Gr.unit = "");
                class Hr extends $t {}
                (Hr.matches =
                    /^list-style:(url|linear-gradient|radial-gradient|repeating-linear-gradient|repeating-radial-gradient|conic-gradient)\(.*\)((?!\|).)*$/),
                    (Hr.key = o(Ee, Oe, $));
                class Jr extends $t {}
                (Jr.matches =
                    /^shape:((margin|content|border|padding)(?!\|)|(inset|circle|ellipse|polygon|url|linear-gradient)\(.*\)((?!\|).)*$)/),
                    (Jr.key = o(Ue, "outside")),
                    (Jr.values = {
                        content: o(N, ne),
                        border: o(d, ne),
                        padding: o(f, ne),
                        margin: o(u, ne),
                    });
                class Kr extends $t {}
                (Kr.matches =
                    /^shape:([0-9]|(max|min|calc|clamp)\(.*\))((?!\|).)*$/),
                    (Kr.key = o(Ue, u));
                class en extends $t {}
                (en.key = o(Ue, $, "threshold")), (en.unit = "");
                class tn extends $t {}
                (tn.matches = /^clip:./),
                    (tn.key = o(ce, "path")),
                    (tn.values = {
                        content: o(N, ne),
                        border: o(d, ne),
                        padding: o(f, ne),
                        margin: o(u, ne),
                        fill: o(ae, ne),
                        stroke: o(I, ne),
                        view: o(Xe, ne),
                    });
                class sn extends $t {
                    constructor() {
                        super(...arguments), (this.order = -1);
                    }
                }
                sn.key = D;
                class rn extends $t {
                    constructor() {
                        super(...arguments), (this.order = -1);
                    }
                }
                (rn.matches = /^f:./),
                    (rn.key = m),
                    (rn.unit = ""),
                    (rn.colorful = !0);
                class nn extends $t {}
                nn.key = "quotes";
                class an extends $t {
                    constructor() {
                        super(...arguments), (this.order = -1);
                    }
                }
                an.key = o(D, g);
                class cn extends $t {}
                (cn.key = o(D, w, Be)), (cn.unit = "");
                class ln extends $t {}
                ln.key = o(D, g, Ye) + "s";
                class on extends $t {}
                (on.matches = /^grid-template-cols:./),
                    (on.key = o(D, g, k)),
                    (on.values = { min: o(U, N), max: o(Q, N) });
                class dn extends $t {}
                (dn.key = o(D, g, O)),
                    (dn.values = { min: o(U, N), max: o(Q, N) });
                class hn extends $t {
                    constructor() {
                        super(...arguments), (this.order = -1);
                    }
                }
                (hn.key = o(D, Ye)), (hn.unit = "");
                class un extends $t {}
                (un.matches = /^grid-col-end:./),
                    (un.key = o(D, y, "end")),
                    (un.unit = "");
                class fn extends $t {}
                (fn.key = o(D, w, "end")), (fn.unit = "");
                class mn extends $t {
                    get props() {
                        return {
                            "mask-image": this,
                            "-webkit-mask-image": this,
                        };
                    }
                }
                mn.key = o("mask", $);
                class pn extends $t {
                    get props() {
                        return { "-webkit-text-fill-color": this };
                    }
                }
                (pn.id = "textFillColor"),
                    (pn.matches = /^text-fill-color:./),
                    (pn.colorStarts = "text-fill:"),
                    (pn.colorful = !0),
                    (pn.values = { current: p });
                class xn extends $t {
                    get props() {
                        return { "-webkit-text-stroke": this };
                    }
                }
                (xn.id = "textStroke"), (xn.matches = /^text-stroke:./);
                class bn extends $t {
                    get props() {
                        return { "-webkit-text-stroke-width": this };
                    }
                }
                (bn.id = "textStrokeWidth"),
                    (bn.matches =
                        /^text-stroke(:((thin|medium|thick)(?!\|)|\.?\d((?!\|).)*$)|-width:.)/);
                class gn extends $t {
                    get props() {
                        return { "-webkit-text-stroke-color": this };
                    }
                }
                (gn.id = "textStrokeColor"),
                    (gn.matches = /^text-stroke-color:./),
                    (gn.colorStarts = "text-stroke:"),
                    (gn.colorful = !0),
                    (gn.values = { current: p });
                class yn extends $t {}
                yn.key = o(I, "dasharray");
                class kn extends $t {}
                kn.key = o(I, "dash") + Ze;
                class vn extends $t {}
                (vn.key = "x"), (vn.unit = "");
                class wn extends $t {}
                (wn.key = "y"), (wn.unit = "");
                class On extends $t {}
                (On.key = "cx"), (On.unit = "");
                class jn extends $t {}
                (jn.key = "cy"), (jn.unit = "");
                class Sn extends $t {}
                (Sn.key = "rx"), (Sn.unit = "");
                class Nn extends $t {}
                (Nn.key = "ry"), (Nn.unit = "");
                class $n extends $t {}
                $n.key = o(d, $, "outset");
                class In extends $t {}
                (In.matches =
                    /^border-image:(?:stretch|repeat|round|space)(?:(?!\|).)*$/),
                    (In.key = o(d, $, A));
                class Rn extends $t {}
                (Rn.key = o(d, $, "slice")), (Rn.unit = "");
                class zn extends $t {}
                (zn.matches =
                    /^border-image:(?:url|linear-gradient|radial-gradient|repeating-linear-gradient|repeating-radial-gradient|conic-gradient)\(.*\)(?:(?!\|).)*$/),
                    (zn.key = o(d, $, "source"));
                class Wn extends $t {}
                (Wn.matches =
                    /^border-image:(?:\.?[0-9]|(max|min|calc|clamp)\(.*\))(?:(?!\|).)*$/),
                    (Wn.key = o(d, $, F));
                class An extends $t {}
                (An.key = o(d, $)), (An.unit = "");
                const Cn = /\{(.*)\}/;
                class En extends $t {
                    get props() {
                        const e = {},
                            t = (t) => {
                                const s = t.indexOf(":");
                                if (-1 !== s) {
                                    const i = t.slice(0, s);
                                    i in e ||
                                        (e[i] = { value: t.slice(s + 1) });
                                }
                            },
                            s = (e) => {
                                const s = e.text
                                    .slice(CSS.escape(e.name).length)
                                    .match(Cn)[1]
                                    .split(";");
                                for (const e of s) t(e);
                            },
                            i = [];
                        let r = "";
                        const n = () => {
                            r && (i.push(r), (r = ""));
                        };
                        let c = 1;
                        const l = (e) => {
                            for (; c < this.value.length; c++) {
                                const t = this.value[c];
                                if (!e) {
                                    if (";" === t) {
                                        n();
                                        continue;
                                    }
                                    if ("}" === t) break;
                                }
                                if (((r += t), e === t)) {
                                    if ("'" === e) {
                                        let e = 0;
                                        for (
                                            let t = r.length - 2;
                                            "\\" === r[t];
                                            t--
                                        )
                                            e++;
                                        if (e % 2) continue;
                                    }
                                    break;
                                }
                                t in a && "'" !== e && (c++, l(a[t]));
                            }
                        };
                        l(void 0), n();
                        for (const e of i) {
                            const i = Ct.findAndNew(e);
                            if (Array.isArray(i)) for (const e of i) s(e);
                            else i ? s(i) : t(e);
                        }
                        return e;
                    }
                }
                (En.id = Ce),
                    (En.matches = /^(?:.+?[*_>~+])?\{.+?\}/),
                    (En.unit = "");
                class Mn extends $t {}
                (Mn.key = "counter-increment"), (Mn.unit = "");
                var Vn = s(580),
                    _n = s.n(Vn);
                class Ln extends $t {}
                Ln.semantics = {
                    "center-content": {
                        "justify-content": "center",
                        "align-items": "center",
                    },
                    "sr-only": {
                        position: "absolute",
                        width: "1px",
                        height: "1px",
                        padding: "0",
                        margin: "-1px",
                        overflow: "hidden",
                        clip: "rect(0,0,0,0)",
                        "white-space": "nowrap",
                        "border-width": "0",
                    },
                    full: { width: "100%", height: "100%" },
                    center: {
                        left: 0,
                        right: 0,
                        "margin-left": ge,
                        "margin-right": ge,
                    },
                    middle: {
                        top: 0,
                        bottom: 0,
                        "margin-top": ge,
                        "margin-bottom": ge,
                    },
                    "break-spaces": { "white-space": o(xe, me) + "s" },
                    "break-word": { "overflow-wrap": o(xe, fe), overflow: re },
                };
                const Tn = "undefined" != typeof window,
                    qn = [
                        Ln,
                        En,
                        Ei,
                        Et,
                        Vt,
                        Hi,
                        Bt,
                        hr,
                        dr,
                        _t,
                        Qi,
                        Fi,
                        Zi,
                        qi,
                        Pi,
                        Ui,
                        Ss,
                        Dt,
                        Gt,
                        Ht,
                        Jt,
                        Ur,
                        kr,
                        Mn,
                        Kt,
                        is,
                        rs,
                        ns,
                        rn,
                        as,
                        cs,
                        ls,
                        os,
                        xr,
                        br,
                        gr,
                        Dr,
                        ds,
                        Tr,
                        ur,
                        Fr,
                        hs,
                        Yr,
                        yr,
                        us,
                        Pr,
                        Zr,
                        qr,
                        fs,
                        Os,
                        js,
                        Ns,
                        $s,
                        Is,
                        Rs,
                        zs,
                        Ws,
                        As,
                        Cs,
                        Es,
                        ui,
                        fi,
                        ki,
                        vi,
                        wi,
                        Oi,
                        Ri,
                        Ci,
                        ji,
                        Si,
                        $i,
                        Ii,
                        pn,
                        bn,
                        gn,
                        xn,
                        Ni,
                        Ir,
                        xs,
                        ps,
                        ms,
                        bs,
                        vs,
                        ws,
                        ks,
                        ys,
                        gs,
                        Ms,
                        Vs,
                        Xr,
                        _s,
                        Ls,
                        Ts,
                        qs,
                        Ps,
                        Zs,
                        Qs,
                        Hs,
                        Js,
                        Ks,
                        Nr,
                        $r,
                        ei,
                        $n,
                        In,
                        Rn,
                        zn,
                        Wn,
                        An,
                        ti,
                        si,
                        ii,
                        ri,
                        ni,
                        ai,
                        ci,
                        li,
                        oi,
                        di,
                        hi,
                        pi,
                        yi,
                        xi,
                        yn,
                        kn,
                        gi,
                        bi,
                        vn,
                        wn,
                        On,
                        jn,
                        Sn,
                        Nn,
                        Gr,
                        un,
                        Bi,
                        zi,
                        cn,
                        fn,
                        Gi,
                        Wi,
                        tr,
                        sr,
                        ir,
                        ln,
                        on,
                        dn,
                        an,
                        hn,
                        sn,
                        Ai,
                        Yi,
                        Ti,
                        Li,
                        _i,
                        Vi,
                        Mi,
                        Di,
                        Ji,
                        Ki,
                        er,
                        rr,
                        nr,
                        ar,
                        cr,
                        lr,
                        or,
                        fr,
                        mr,
                        Hr,
                        pr,
                        vr,
                        wr,
                        Or,
                        jr,
                        Sr,
                        Rr,
                        zr,
                        Wr,
                        Ar,
                        Cr,
                        Er,
                        Mr,
                        Vr,
                        _r,
                        Lr,
                        Qr,
                        Br,
                        Jr,
                        Kr,
                        en,
                        tn,
                        nn,
                        mn,
                    ],
                    Pn = (qn.get = (e) =>
                        qn.find((t) => {
                            var s;
                            return (
                                e === t.id ||
                                e ===
                                    (null === (s = t.key) || void 0 === s
                                        ? void 0
                                        : s.replace(/-./g, (e) =>
                                              e[1].toUpperCase()
                                          )) ||
                                e === t.key
                            );
                        }));
                function Zn() {
                    if (Tn) {
                        const e = new Ct(document.head);
                        (Ct.root = e), e.observe(document.documentElement);
                    }
                }
                (qn.extend = function (e, ...t) {
                    const s = Object.assign({}, ...t);
                    for (const t in s) {
                        const i = Pn(t);
                        if (i) {
                            const r = s[t];
                            i.extend(e, r);
                        }
                    }
                    return this;
                }),
                    $t.extend("colors", _n()).extend("breakpoints", l()),
                    Ct.Styles.push(...qn);
                Tn &&
                    ((window.initMasterCSS = Zn),
                    (window.MasterStyles = qn),
                    window.MasterCSSManual || Zn());
                const Fn = s(580),
                    Qn = s(642);
            })(),
            i
        );
    })();
});
