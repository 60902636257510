const sharedBannerOptions = {
	speed: 1700,
	autoplay: {
		delay: 6000,
	},
	effect: "fade",
	fadeEffect: { crossFade: true },
};

try {
	const bannerHomeSlider = new Swiper(
		".home-banner-slider .swiper-container",
		{
			pagination: {
				el: ".home-banner-slider  .swiper-pagination",
				clickable: true,
			},
			...sharedBannerOptions,
		}
	);
	if (bannerHomeSlider.slides.length <= 1) {
		bannerHomeSlider.autoplay.stop();
	}
} catch (error) {}

try {
	const bannerSubSlider = new Swiper(".sub-banner-slider .swiper-container", {
		pagination: {
			el: ".sub-banner-slider  .swiper-pagination",
			clickable: true,
		},
		...sharedBannerOptions,
	});
	if (bannerSubSlider.slides.length <= 1) {
		bannerSubSlider.autoplay.stop();
	}
} catch (error) {}

const imagesSlider = new Swiper(".images-slider .swiper-container", {
	autoplay: {
		delay: 6000,
	},
	spaceBetween: 4,
	navigation: {
		prevEl: ".images-slider .swiper-prev",
		nextEl: ".images-slider .swiper-next",
	},
	breakpoints: {
		768: {
			slidesPerView: 1.15,
			centeredSlides: true,
		},
	},
});

const newsSliderInit = () => {
	$(".news-slider").each(function (index) {
		var $this = $(this);
		if ($(this).data("slides") !== undefined) {
			var initSlide = $(this).data("slides");
		} else {
			var initSlide = 3;
		}
		$this.addClass("news-slider-" + index);
		$this.find(".swiper-prev").addClass("news-slider-prev-" + index);
		$this.find(".swiper-next").addClass("news-slider-next-" + index);

		var newsSlider = new Swiper(
			".news-slider-" + index + " .swiper-container",
			{
				slidesPerView: initSlide,
				spaceBetween: 32,
				navigation: {
					prevEl: ".news-slider-prev-" + index,
					nextEl: ".news-slider-next-" + index,
				},
				breakpoints: {
					1024: {
						slidesPerView: 3.5,
						spaceBetween: 16,
						freeMode: true,
					},
					768: {
						slidesPerView: 2.5,
						freeMode: true,
						spaceBetween: 16,
					},
					576: {
						slidesPerView: 1.5,
						spaceBetween: 16,
						freeMode: true,
					},
				},
			}
		);
	});
};

newsSliderInit();

const productSliderInit = () => {
	$(".product-slider").each(function (index) {
		var $this = $(this);
		if ($(this).data("slides") !== undefined) {
			var initSlide = $(this).data("slides");
		} else {
			var initSlide = 4;
		}
		$this.addClass("product-slider-" + index);
		$this.find(".swiper-prev").addClass("product-slider-prev-" + index);
		$this.find(".swiper-next").addClass("product-slider-next-" + index);

		var productSlider = new Swiper(
			".product-slider-" + index + " .swiper-container",
			{
				slidesPerView: initSlide,
				spaceBetween: 32,
				watchSlidesVisibility: true,
				navigation: {
					prevEl: ".product-slider-prev-" + index,
					nextEl: ".product-slider-next-" + index,
				},
				breakpoints: {
					1024: {
						slidesPerView: 3.5,
						freeMode: true,
						spaceBetween: 16,
					},
					768: {
						slidesPerView: 2.5,
						freeMode: true,
						spaceBetween: 16,
					},
					576: {
						slidesPerView: 1.5,
						slidesPerColumn: 1,
						freeMode: true,
						spaceBetween: 16,
					},
				},
			}
		);
	});
};

productSliderInit();

const manufactureStandardSlider = new Swiper(
	".manufacture-standard-slider .swiper-container",
	{
		slidesPerView: 3,
		spaceBetween: 32,
		navigation: {
			prevEl: ".manufacture-standard-slider .swiper-prev",
			nextEl: ".manufacture-standard-slider .swiper-next",
		},
		breakpoints: {
			1024: {
				slidesPerView: 2.5,
				spaceBetween: 16,
			},
			768: {
				slidesPerView: 1.25,
				spaceBetween: 16,
			},
		},
	}
);

if ($(".manufacture-standard-slider .swiper-slide").length < 3) {
	$(".manufacture-standard-slider .swiper-wrapper").addClass(
		"justify-center"
	);
}

const historySlider = new Swiper(".history-slider .swiper-container", {
	slidesPerView: 4,
	spaceBetween: 32,
	navigation: {
		prevEl: ".history-slider .swiper-prev",
		nextEl: ".history-slider .swiper-next",
	},
	breakpoints: {
		1024: {
			slidesPerView: 3.5,
			spaceBetween: 16,
		},
		768: {
			slidesPerView: 2.25,
			spaceBetween: 16,
		},
		576: {
			slidesPerView: 1.25,
			spaceBetween: 16,
		},
	},
});
