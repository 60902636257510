const topMenuMapping = new MappingListener({
	selector: ".top-menu-wrapper",
	mobileWrapper: ".menu-mobile-body",
	mobileMethod: "appendTo",
	desktopWrapper: ".header-top-left",
	desktopMethod: "appendTo",
	breakpoint: 1025,
}).watch();

const menuMapping = new MappingListener({
	selector: ".menu-wrapper",
	mobileWrapper: ".menu-mobile-body",
	mobileMethod: "appendTo",
	desktopWrapper: ".menu-toggle",
	desktopMethod: "insertAfter",
	breakpoint: 1025,
}).watch();

const hotlineMapping = new MappingListener({
	selector: ".hotline-wrapper",
	mobileWrapper: ".menu-mobile-header",
	mobileMethod: "appendTo",
	desktopWrapper: ".cart-wrapper",
	desktopMethod: "insertBefore",
	breakpoint: 1025,
}).watch();

$(".close-menu").on("click", function () {
	$(".menu-mobile").removeClass("show");
	$("body").removeClass("overflow-hidden");
});

$(".menu-toggle").on("click", function () {
	$(".menu-mobile").addClass("show");
	$("body").addClass("overflow-hidden");
});

var $globalHeader = $(".global-header");
var scrollTop = $(window).scrollTop();
$globalHeader.toggleClass("is-scrolling", scrollTop > 10);

$(window).scroll(function () {
	var scrollTop = $(window).scrollTop();
	$globalHeader.toggleClass("is-scrolling", scrollTop > 10);
});

$globalHeader.sticky({
	topSpacing: 0,
	zIndex: 101,
});

$(".toggle-sub-menu").each(function (index, el) {
	$(el).on("click", function () {
		$(el).next().slideToggle();
	});
});

$(".about-nav").sticky({
	topSpacing: $globalHeader.height(),
	zIndex: 100,
});

$(".search-toggle").on("click", function (e) {
	e.preventDefault();
	$(".search-popup").fadeIn();
	$(".search-backdrop").fadeIn();
	$(".searchinput").focus();
	$("body").addClass("overflow-hidden");
});

$(".search-backdrop").on("click", function (e) {
	e.preventDefault();
	$(".search-popup").fadeOut();
	$(".search-backdrop").fadeOut();
	$(".searchinput").trigger("change");
	$(".searchinput").val("");
	$("body").removeClass("overflow-hidden");
});
