$(".promotion-item").each(function () {
	let _this = $(this);

	_this.find(".order-up").on("click", function (e) {
		e.preventDefault();
		if (_this.prev().length > 0) {
			_this.insertBefore(_this.prev());
		} else {
			_this.appendTo(_this.parent());
		}
		updateIds();
	});

	_this.find(".order-down").on("click", function (e) {
		e.preventDefault();
		if (_this.next().length > 0) {
			_this.insertAfter(_this.next());
		} else {
			_this.prependTo(_this.parent());
		}
		updateIds();
	});
});

function updateIds() {
	$(".promotion-item").each(function (index) {
		$(this).attr("data-position", index + 1);
	});
}
