var lazyLoadInstance = new LazyLoad({
	// Your custom settings go here
});
window.lazyLoadInstance = lazyLoadInstance;
import "../_plugins/@master/css";
import "./partial/Header";
import "./partial/Slider";
import "./partial/Culture";
import "./partial/Dealer";
import "./partial/ProductDetail";
import "./partial/ExpandContent";
import "./partial/MoveNode";
import "./partial/Toggle";
import "./partial/BackToTop";
import "./partial/Promotion";
jQuery(function () {
	if ($(".before-login").length > 0) {
		$(".account .dropdown-content").remove();
	}

	$(".select-change-link").on("change", function () {
		var url = $(this).val(); // Get the URL from the selected option
		if (url) {
			// If a URL was selected
			window.location = url; // Go to the URL
		}
	});

	let dotConnect = $(".dealer-group-item .dot").connections({
		css: {
			border: "1px solid #D9D9D9",
			zIndex: 1,
		},
		tag: "div",
		within: ".dealer-map-hover",
	});
	$(window).on("resize", function () {
		dotConnect.connections("update");
	});

	SVGInject(document.querySelector(".inject-svg"), {
		// useCache: false,
		afterInject: function (img, svg) {
			const $dealerMap = $(".dealer-map svg");
			$(".dealer-group").on("mouseenter", function () {
				const $this = $(this);
				const type = $this.data("type");
				$dealerMap.find(`.pinpoint-group`).addClass("no-hovering");
				$dealerMap
					.find(`.pinpoint-group[data-type="${type}"]`)
					.removeClass("no-hovering")
					.addClass("hovering");
				setInterval(() => dotConnect.connections("update"), 100);
				scrollToElement("#dealer-map-hover", 300);
			});

			$(".dealer-group").on("mouseleave", function () {
				$dealerMap.find(`.pinpoint-group`).removeClass("no-hovering");
				$dealerMap
					.find(
						`.pinpoint-group[data-type="${$(this).data("type")}"]`
					)
					.removeClass("no-hovering")
					.removeClass("hovering");
				setInterval(() => dotConnect.connections("update"), 100);
			});
		},
	});

	$(".about-nav a").on("click", function (e) {
		scrollToElement(
			$(this).attr("href"),
			1000,
			$(".global-header").height() + $(".about-nav").height()
		);
	});
	var header = document.querySelector(".global-header");
	var spy = new Gumshoe(".about-nav a", {
		offset: function () {
			return (
				header.getBoundingClientRect().height + $(".about-nav").height()
			);
		},
	});
});

var scrollToElement = function (el, ms, height) {
	console.log($(el));
	var speed = ms ? ms : 2000;
	let scrollHeight;
	if (height) {
		scrollHeight = $(el).offset().top - height;
	} else {
		scrollHeight = $(el).offset().top - $(".global-header").outerHeight();
	}
	$("html,body").animate(
		{
			scrollTop: scrollHeight,
		},
		speed
	);
};
